import * as React from 'react';
import { ContextualMenu, getId, Icon, IDragOptions, List, Modal } from "@fluentui/react";
import { StyleUtils } from '../common/StyleUtils';
import { Utils } from '../common/Utils';
import CustomerView from './CustomerView';
import { ShortCustomerModel } from '../../models/customers/CustomerModel';

interface ICustomerPickerProps {
    onSelect: (customer?: ShortCustomerModel) => void;
}

interface ICustomerPickerState {
    value?: number;
    text?: string;
    showMenu: boolean;
    listHash: number;
}

export class CustomerPicker extends React.Component<ICustomerPickerProps, ICustomerPickerState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: ICustomerPickerProps) {
        super(props);
        this.state = {
            showMenu: false,
            listHash: -1
        };
    }

    render() {
        const { text } = this.state;
        return <>
            <div className="pb-5">
                <label className="pr-5 bold">Customer</label>
                <Icon className="pointer" iconName="cancel" onClick={this.onClear} />
            </div>
            <div className="brand-dropdown" onClick={this.showMenu}>
                <div className="brand-dropdown-text">{text}</div>
                <div className="brand-dropdown-icon"><Icon iconName="ChevronDown" /></div>
            </div>

            {this.modalElement()}
        </>;
    }

    private modalElement = () => {
        const { showMenu } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={showMenu}
                onDismiss={this.hideMenu}
                isBlocking={true}
                dragOptions={this._dragOptions}
                styles={StyleUtils.getModalWidthStyles(450)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Customers</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={this.hideMenu} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body pv-20" style={{ height: "70vh", overflowY: "auto" }}>
                    <CustomerView onSelect={this.onSelectedChange} />
                </div>
            </Modal>
        </>;
    }

    private onSelectedChange = (customer: ShortCustomerModel) => {
        const { onSelect } = this.props;
        this.setState({ value: customer.id, text: customer.displayName, showMenu: false });

        onSelect(customer);
    }

    private onClear = () => {
        const { onSelect } = this.props;
        this.setState({ value: undefined, text: undefined });
        onSelect(undefined);
    }

    private showMenu = () => {
        this.setState({ showMenu: true });
    }

    private hideMenu = () => {
        this.setState({ showMenu: false });
    }
}