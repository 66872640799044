import * as React from 'react';
import "../navbar/navbar.scss";
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { Authentication } from './Authentication';
import { Utils } from './Utils';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { DataUtilities } from './DataUtilities';
import { HomeService } from '../../services/HomeService';
import { ContactInfo, ContactType } from '../../models/others/ContactInfo';
import { PaymentAccountInfo } from '../../models/payments/PaymentAccountInfo';

interface IFooterViewProps { }

interface IFooterViewState {
    userRoles: UserRolesInfo;
    contacts: ContactInfo[];
    paymentAccounts: PaymentAccountInfo[];
}

export default class FooterView extends React.Component<IFooterViewProps, IFooterViewState> {
    constructor(props: IFooterViewProps) {
        super(props);

        this.state = {
            contacts: [],
            paymentAccounts: [],
            userRoles: Authentication.getUserRoles()
        };
    }

    componentDidMount() {
        this.getContacts();
        this.getPaymentAccounts();
    }
    render() {
        const { userRoles } = this.state;
        const width6 = Utils.createPanelStyle(6, 12, 12);

        return <div className="ms-Grid footer primary-background-color"
            style={{ textAlign: "left", padding: "20px 20px 0px 20px" }}>
            <div className="ms-Grid-row">
                <div className={width6}>{this.printCompanyPayments()}</div>
                <div className={width6}>{this.printCompanyContacts()}</div>
            </div>
        </div>;
    }

    private printCompanyPayments = () => {
        const { paymentAccounts } = this.state;
        const labelWidth = Utils.createPanelStyle(5);
        const valueWidth = Utils.createPanelStyle(7);
        const display = "inline-block";

        return paymentAccounts.map((account: PaymentAccountInfo, index: number) => {
            return <div className="ms-Grid-row" key={"payment-" + index}
                style={{ paddingBottom: 0 }}>
                <div className={labelWidth} style={{ display: display }}>{account.provider}:</div>
                <div className={valueWidth} style={{ display: display }}>
                    {Utils.formatAccountNumber(account.account)}
                </div>
            </div>;
        });
    }

    private printCompanyContacts = () => {
        const { contacts } = this.state;
        const labelWidth = Utils.createPanelStyle(3);
        const valueWidth = Utils.createPanelStyle(9);
        const color = "rgb(228, 196, 107)";
        const phonesEmails = contacts.filter((value: ContactInfo) => value.type !== ContactType.url);
        const urls = contacts.filter((value: ContactInfo) => value.type === ContactType.url);

        return <>
            {phonesEmails.map((contact: ContactInfo, index: number) => {
                return <div className="ms-Grid-row" key={index} style={{ paddingBottom: 0 }}>
                    <div className={labelWidth}>{contact.name}:</div>

                    {contact.type === ContactType.phone && <div className={valueWidth}>
                        {Utils.formatPhone(contact.account1)}
                    </div>}

                    {contact.type === ContactType.email && <div className={valueWidth}>
                        <a href={"mailto:" + contact.account1} className="clickable main-color">{contact.account1}</a>
                    </div>}
                </div>;
            })}

            {!Utils.isArrayNullOrEmpty(urls) && <div className="ms-Grid-row pl-10" style={{ paddingBottom: 0 }}>
                {urls.map((contact: ContactInfo, index: number) => {
                    return <div style={{ display: "inline-block" }}>
                        {index > 0 && <span className="ph-10">|</span>}
                        <a href={contact.account1} target="_blank"
                            className="clickable" style={{ color: "black" }}>{contact.name}</a>
                    </div>;
                })}
            </div>}
        </>;
    }

    private getContacts = () => {
        const onResult = (response: IDataResponseResult) => {
            if (response.status === ResponseStatus.success) {
                this.setState({ contacts: response.data });
            }
        }

        DataUtilities.onRequest(HomeService.getContacts(), onResult);
    }

    private getPaymentAccounts = () => {
        const onResult = (response: IDataResponseResult) => {
            if (response.status === ResponseStatus.success) {
                this.setState({ paymentAccounts: response.data });
            }
        }

        DataUtilities.onRequest(HomeService.getPaymentAccounts(), onResult);
    }
}