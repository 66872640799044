import { DatePicker, IDatePickerProps, IDatePickerStrings } from '@fluentui/react';
import * as React from 'react';

const DayPickerStrings: IDatePickerStrings = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    goToToday: "Today",
    prevMonthAriaLabel: "Previous Month",
    nextMonthAriaLabel: "Next Month",
    prevYearAriaLabel: "Previous Year",
    nextYearAriaLabel: "Next Year"
};

interface IBasicDatePickerProps {
    args: IDatePickerProps;
}

export const BasicDatePicker: React.FC<IBasicDatePickerProps> = (props: IBasicDatePickerProps) => {
    return <div>
        <DatePicker strings={DayPickerStrings} placeholder="Select a date" {...props.args} />
    </div>;
}