import * as $ from 'jquery';
import { apiBaseUrl } from '../constants/url.constant';
import {
    ApproveOrderModel, ChangeWebsiteOrderModel, EditOrderModel,
    NewOrderModel, OrderFilterModel
} from "../models/orders/OrderModel";
import { UploadPictureModel } from '../models/products/PictureModel';
import { EditProductModel } from '../models/products/ProductModel';
import { DefaultService } from "./DefaultService";

export class OrderService extends DefaultService {
    private static controller: string = "Order";

    public static RouteGet = (id: number) => `/${this.controller}/get/${id}`;
    public static get(id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteGet(id), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteCreate = () => `/${this.controller}/create`;
    public static create(model: NewOrderModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteCreate(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteSearch = () => `/${this.controller}/search`;
    public static search(model: OrderFilterModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteSearch(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteGetCountByStatus = () => `/${this.controller}/getCountByStatus`;
    public static getCountByStatus(model: OrderFilterModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteGetCountByStatus(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RoutePlaceOrder = (id: number) => `/${this.controller}/placeOrder/${id}`;
    public static placeOrder(id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RoutePlaceOrder(id), {
            type: 'put',
            contentType: 'application/json'
        });
    }

    public static RouteApprove = () => `/${this.controller}/approve`;
    public static approve(model: ApproveOrderModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteApprove(), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteEditPrice = () => `/${this.controller}/editPrice`;
    public static editPrice(model: EditOrderModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteEditPrice(), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteEditProduct = (id: number) => `/${this.controller}/editProduct/${id}`;
    public static editProduct(id: number, model: EditProductModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteEditProduct(id), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteChangeWebiste = () => `/${this.controller}/changeWebsite`;
    public static changeWebiste(model: ChangeWebsiteOrderModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteChangeWebiste(), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteChangeWarehouse = (id: number, newWarehouseId: number) =>
        `/${this.controller}/changeWarehouse/${id}?newWarehouseId=${newWarehouseId}`;
    public static changeWarehouse(id: number, newWarehouseId: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteChangeWarehouse(id, newWarehouseId), {
            type: 'put',
            contentType: 'application/json'
        });
    }

    public static RouteUnassignWarehouse = (id: number) => `/${this.controller}/unassignWarehouse/${id}`;
    public static unassignWarehouse(id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + OrderService.RouteUnassignWarehouse(id), {
            type: 'put',
            contentType: 'application/json'
        });
    }

    public static RouteCreatePicture = (id: number) => `/${this.controller}/createPicture/${id}`;
    //public static createPicture(id: number, model: UploadPictureModel): JQueryPromise<any> {
    //    return $.ajax(apiBaseUrl + OrderService.RouteCreatePicture(id), {
    //        type: 'post',
    //        data: JSON.stringify(model),
    //        contentType: 'application/json'
    //    });
    //}
}