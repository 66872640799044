import * as React from 'react';
import { DirectionalHint, mergeStyleSets, TooltipHost, getId, TooltipDelay } from '@fluentui/react';

const classNames = mergeStyleSets({
    plainCard: {
        padding: 10,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    textField: {
        paddingRight: 200
    }
});

interface ITooltipProps {
    element: JSX.Element;
    text?: string;
    displayElement?: JSX.Element;
}

export class MyTooltip extends React.Component<ITooltipProps> {
    private _hostId: string = getId('tooltipHost');

    constructor(props: ITooltipProps) {
        super(props);
    }

    public render() {
        return (<>
            <TooltipHost
                tooltipProps={{
                    onRenderContent: () => {
                        return <>
                            <div>{this.props.text}</div>
                            {this.props.displayElement && this.props.displayElement}
                        </>;
                    }
                }}
                delay={TooltipDelay.zero}
                id={this._hostId}
                directionalHint={DirectionalHint.bottomCenter}
                styles={{ root: { display: 'inline-block' } }}

            >
                {this.props.element}
            </TooltipHost>
        </>);
    }
}