export class Page {
    public pageCount: number = 0;
    public totalItemCount: number = 0;
    public pageNumber: number = 1;
    public pageSize: number = 50;
    public hasPreviousPage: boolean = false;
    public hasNextPage: boolean = false;
    public isFirstPage: boolean = false;
    public isLastPage: boolean = false;
    public firstItemOnPage: number = 0;
    public lastItemOnPage: number = 0;
    public hidePageCount: boolean = false;
}