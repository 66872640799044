import * as $ from 'jquery';
import { apiBaseUrl } from "../constants/url.constant";
import { DefaultService } from "./DefaultService";

export class HomeService extends DefaultService {
    private static controller: string = "Home";

    public static RouteGetContacts = () => `/${this.controller}/getContacts`;
    public static getContacts(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetContacts(), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteGetPaymentAccounts = () => `/${this.controller}/getPaymentAccounts`;
    public static getPaymentAccounts(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetPaymentAccounts(), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteGetBanners = () => `/${this.controller}/getBanners`;
    public static getBanners(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetBanners(), {
            type: 'get',
            contentType: 'application/json'
        });
    }
}