import { PagedRow, PageFilterModel } from "../others/PageTypes";
import { ProductModel } from "../products/ProductModel";
import { WarehouseModel } from "../products/WarehouseModel";

export enum OrderStatus {
    all = 0,
    request = 1,
    quote = 2,
    orderPending = 3,
    orderApproved = 4,
    canceled = 5,
    declined = 6,
    completed = 7,
    closed = 8
}

export enum OrderRejectReason {
    none = 0,
    changeMind = 1,
    damaged = 2,
    outOfStock = 3,
    priceTooHigh = 4,
    waitToLong = 5
}

export enum OrderTracking {
    none = 0,
    placedOnline = 1,
    inForeignStock = 2,
    shippedToDestination = 3,
    arrivedDestination = 4,
    readyForDelivery = 5,
    outForDelivery = 6,
    delivered = 7
}

export enum InvoiceStatus {
    all = 0,
    pending = 1,
    processing = 2,
    confirmed = 3,
    readyForDelivery = 4,
    outForDelivery = 5,
    completed = 6
}

export class OrderTrackingInfo {
    order: string = "";
    key: string = "";
    name: string = "";
    status: string = "";
}

export class NewOrderModel {
    customerId: number = 0;
    customer: string = "";
    link: string = "";
    qty: number = 0;
    note: string = "";
}

export class ApproveOrderModel {
    id: number = 0;
    status: OrderStatus = OrderStatus.request;
    reason?: OrderRejectReason;
    tracking?: OrderTracking;
    note?: string;
}

export class EditOrderModel {
    id: number = 0;
    price: number = 0;
    qty: number = 0;
    rate: number = 0;
    domesticShipping: number = 0;
    chargeWeight: number = 0;
    shippingRate: number = 0.0;
    note?: string;
    trackingNo?: string;
}

export class OrderModel extends EditOrderModel implements PagedRow {
    customerId: number = 0;
    customer: string = "";
    userId: string = "";
    product: ProductModel = new ProductModel();
    warehouse: WarehouseModel = new WarehouseModel();
    invoiceId?: string;
    holdPayment: number = 0.0;
    date: Date = new Date(0);

    status: OrderStatus = OrderStatus.request;
    reason?: OrderRejectReason = undefined;
    tracking?: OrderTracking = undefined;
    trackings: OrderTrackingInfo[] = [];
    earningPointRate: number = 0.0;

    isOpen: boolean = false;
    earningPoint: number = 0;
    earningPointAmount: number = 0;
}

export class OrderFilterModel extends PageFilterModel {
    customerId?: number;
    customer?: string;
    isAllWarehouses: boolean = false;
    isOpen: boolean = false;
    isUnInvoiced: boolean = false;
    warehouseId: number = 0;
    status: OrderStatus = OrderStatus.all;
    reason?: OrderRejectReason;
    tracking?: OrderTracking;
}

export class ChangeWebsiteOrderModel {
    id: number = 0;
    websiteId?: number;
    earningPointRate: number = 0;
}