import {
    IComboBoxOption, Icon, IDropdownOption, Link, DefaultButton,
    IContextualMenuProps, TextField, IconButton
} from '@fluentui/react';
import { CustomerDropdownItem } from "../../models/others/OtherTypes";
import { PagedRow } from "../../models/others/PageTypes";
import { DataUtilities } from '../common/DataUtilities';
import { MyTooltip } from "../common/MyTooltip";
import { Utils } from "../common/Utils";
import { CustomerDropdown } from "../customers/CustomerDropdown";
import { IGridColumn, onRender, onColumnClick, emptyFunc, IconName, onRowAction, RowActionInfo } from "./Grid";
import { CheckboxFilter, ComboBoxCallback, ComboboxFilter, DropdownFilter, HiddenFilter } from "./GridFilters";

export type ITextFieldOnChange = (event: any, value: any) => void;

export class GridActionInfo {
    public key: string = "";
    public text: string = "";
    public iconName: IconName = IconName.Add;
}

export class GridUtilities {
    public static DefaultIconText: any = {
        "Cancel": "Cancel",
        "CustomList": "Details",
        "Delete": "Delete",
        "DeliveryTruck": "Delivery",
        "Edit": "Edit",
        "PaymentCard": "Payment",
        "PDF": "Generate PDF",
        "Preview": "Print",
        "PrintfaxPrinterFile": "Scan Receipt",
        "Save": "Save",
        "ViewDashboard": "Dashboard",
        "RectangleShape": "Shipping Label",
        "POISolid": "Change Warehouse",
        "Ringer": "Notifications",
        "Blocked": "Create Hold Payment",
        "BlockedSolid": "Hold Payment List",
        "PictureCenter": "Add Picture",
        "Mail": "Invited Users",
        "Devices3": "Connected Devices",
        "Pinned": "Pin",
        "Unpin": "Unpin",
        "Chat": "Create Chat"
    }

    public static createColumns(obj: any, onRenders: any, onColumnClick: onColumnClick,
        columns: string[] = [], ignoreColumns: string[] = [], widths: any = {},
        labels: any = {}, sortColumns: string[] = []): IGridColumn[] {
        let list: IGridColumn[] = [];

        if (!ignoreColumns) ignoreColumns = [];

        ignoreColumns.push("constructor");
        ignoreColumns.push("RecordInfo");

        if (Utils.isArrayNullOrEmpty(columns)) {
            for (let name in obj) columns.push(name);
        }

        if (Utils.isArrayNullOrEmpty(sortColumns)) {
            sortColumns = columns;
        }

        let defaultWidth = 100;
        for (let i = 0; i < columns.length; i++) {
            const name = columns[i];
            const property = obj[name];
            if (ignoreColumns.indexOf(name) < 0) {
                const className = typeof (property) === "number" ? "right" : "";
                let onRender = onRenders[name];

                if (!onRender) {
                    if (typeof (property) === "number") {
                        defaultWidth = 70;
                        onRender = GridUtilities.CreateNumberRender(name);
                    }
                    else if (property instanceof Date) {
                        defaultWidth = 150;
                        onRender = GridUtilities.CreateDateRender(name);
                    } else {
                        onRender = null;
                    }
                }

                const label = labels[name] ? labels[name] : Utils.splitCapitalText(name);
                let width = widths[name];
                if (Utils.isNullOrEmpty(width)) width = defaultWidth;

                if (sortColumns.indexOf(name) >= 0)
                    list.push(GridUtilities.CreateColumn(name, label, onRender, onColumnClick, width, className));
                else
                    list.push(GridUtilities.CreateColumn(name, label, onRender, undefined, width, className));
            }
        }

        return list;
    }

    public static CreateNumberRender(name: string) {
        return (item: any) => <div className="right">{Utils.formatNumber(item[name])}</div>;
    }

    public static CreateDateRender(name: string) {
        return (item: any) => <span>{Utils.formatShortDate(item[name])}</span>;
    }

    public static CreateColumn(name: string, label: string, onRender: onRender,
        onColumnClick?: onColumnClick, size?: number, className?: string) {
        let obj: IGridColumn = {
            key: name,
            name: label,
            fieldName: name,
            minWidth: 70,
            maxWidth: size,
            isResizable: true,
            isSelected: true,
            className: className
        };

        if (onRender) obj.onRender = onRender;
        if (onColumnClick) obj.onColumnClick = onColumnClick;

        return obj;
    }

    private static getOptionText(key: number, options: IDropdownOption[]) {
        if (!Utils.isArrayNullOrEmpty(options)) {
            const option = options.find((s: IDropdownOption) => s.key === key);
            if (option) return option.text;
        }

        return "";
    }

    public static createFilterTextElement(text: string, onclick: emptyFunc, color = "gray"): JSX.Element {
        return <DefaultButton text={text} className="filter-text" onClick={onclick} style={{ color: color }}>
            {onclick !== null && <Icon iconName={'SkypeCircleMinus'} />}
        </DefaultButton>
    }

    public static createFilterTextElement2(element: JSX.Element, text: string, onclick: emptyFunc): JSX.Element {
        return <div className="filter-text2">
            <MyTooltip element={element} text={text} />
            {onclick && <>
                <Icon iconName={'SkypeCircleMinus'} onClick={onclick}
                    style={{ color: "gray", float: "right", paddingTop: 3 }} />
            </>}
        </div>
    }

    public static getActionInfoForIcon(icon: IconName) {
        const info = new GridActionInfo();
        info.iconName = icon;

        const text = this.DefaultIconText[IconName[icon]];

        if (text) {
            info.key = text;
            info.text = text;
        } else {
            info.key = IconName[icon];
            info.text = IconName[icon];
        }

        return info;
    }

    public static createReadOnlyIcon(icon: IconName): JSX.Element {
        return <Icon className="gray-icon" iconName={IconName[icon]} />
    }

    public static createActionIcon(icon: IconName, item: PagedRow, onclick: onRowAction): JSX.Element {
        return <Link onClick={() => {
            if (onclick) {
                let actionInfo = new RowActionInfo();
                actionInfo.item = item;
                actionInfo.action = icon;
                onclick(actionInfo);
            }
        }}>
            <Icon iconName={IconName[icon]} />
        </Link>
    }

    public static createRowActionIcons(actions: GridActionInfo[], onRowAction: onRowAction): IGridColumn[] {
        const onRender = (item: PagedRow) => {
            return this.createActionMenu(item, actions, onRowAction);
        }

        const column = GridUtilities.CreateColumn("", "", onRender, undefined, 50, "");
        return [column];
    }

    public static createActionMenu(item: PagedRow, actions: GridActionInfo[], onRowAction: onRowAction) {
        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: []
        };

        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            menuProps.items.push({
                key: action.key, text: action.text, iconProps: { iconName: IconName[action.iconName] }, onClick: () => {
                    if (onRowAction) {
                        let actionInfo = new RowActionInfo();
                        actionInfo.item = item;
                        actionInfo.action = action.iconName;
                        onRowAction(actionInfo);
                    }
                }
            });
        }

        return <>
            <IconButton
                menuProps={menuProps}
                iconProps={{ iconName: "More" }}
                checked={false}
                onRenderMenuIcon={() => <div />}
            />
        </>;
    };

    public static createCheckboxFilter(label: string, name: string, value = false): CheckboxFilter {
        let filter = new CheckboxFilter();
        filter.label = label;
        filter.name = name;
        filter.value = value;
        filter.getText = (val: boolean) => val ? label : "";

        return filter;
    }

    public static createHiddenFilter(name: string, value: any): HiddenFilter {
        let filter = new HiddenFilter();
        filter.label = "";
        filter.name = name;
        filter.value = value;
        filter.getText = (val: boolean) => "";

        return filter;
    }

    public static createDropdownFilter(label: string, name: string, isMultiSelect: boolean,
        options: IDropdownOption[]): DropdownFilter {
        let filter = new DropdownFilter();
        filter.label = label;
        filter.name = name;
        filter.isMultiSelect = isMultiSelect;
        filter.getText = (value: any) => !Utils.isNull(value) ?
            label + ": " + DataUtilities.getOptionText(options, value) : "";
        filter.options = options;

        return filter;
    }

    public static createTextField(label: string, value: string, onChange: ITextFieldOnChange,
        width: any, height = 25, borderless = true, color = "black", readonly = false): JSX.Element {
        return <>
            <TextField
                label={label}
                value={value}
                borderless={borderless}
                styles={{
                    fieldGroup: {
                        height: height,
                        borderBottom: borderless ? "1px solid lightgray" : ""
                    },
                    field: {
                        color: color
                    }
                }}
                readOnly={readonly}
                onChange={onChange} />
        </>;
    }

    public static createFormDataRow(label: string, value: JSX.Element,
        labelClassName: string, textClassName: string): JSX.Element {
        return <div className="ms-Grid-row">
            <div className={labelClassName}><label>{label}</label></div>
            <div className={textClassName}>{value}</div>
        </div>
    }

    public static createAddRemoveIcons(name: string, onAdd: emptyFunc, onRemove: emptyFunc) {
        const addLabel = "Add " + name;
        const removeLabel = "Remove " + name;
        return <>
            <IconButton iconProps={{ iconName: "CircleAdditionSolid" }} className="add-icon"
                onClick={onAdd} title={addLabel} ariaLabel={addLabel} />

            <IconButton iconProps={{ iconName: "SkypeCircleMinus" }} className="remove-icon"
                onClick={onRemove} title={removeLabel} ariaLabel={removeLabel} />
        </>;
    }

    public static createCustomerComboboxFilter(): ComboboxFilter {
        let customerCombobox = new ComboboxFilter();
        customerCombobox.label = "Customer";
        customerCombobox.name = "CustomerId";

        customerCombobox.component = (callback: ComboBoxCallback) => <>
            <CustomerDropdown
                defaultValue={new CustomerDropdownItem()}
                onSelectChange={(option: CustomerDropdownItem) => {
                    const comboboxOption: IComboBoxOption = { key: option.id, text: option.name };
                    callback(comboboxOption);
                }} />
        </>;

        customerCombobox.getText = (value: any) => value ? "Customer: " + value : "";

        return customerCombobox;
    }
}