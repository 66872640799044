import * as React from 'react';
import { AccountPhoneModel, AccountProvider, ExtendedAuthorizationTokensInfo, LoginModel } from '../../models/accounts/AccountTypes';
import { IDataResponseResult, IResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { DataUtilities } from '../common/DataUtilities';
import { Utils } from '../common/Utils';
import { DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PhoneView } from './PhoneView';
import { initializeIcons, PrimaryButton, ProgressIndicator, TextField } from '@fluentui/react';
import { AccountService } from '../../services/AccountService';
import { Authentication } from '../common/Authentication';

initializeIcons();

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

const options: IDropdownOption[] = [
    { key: 'fruitsHeader', text: 'Fruits', itemType: DropdownMenuItemType.Header },
    { key: 'apple', text: 'Apple' },
    { key: 'banana', text: 'Banana' },
    { key: 'orange', text: 'Orange', disabled: true },
    { key: 'grape', text: 'Grape' },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'vegetablesHeader', text: 'Vegetables', itemType: DropdownMenuItemType.Header },
    { key: 'broccoli', text: 'Broccoli' },
    { key: 'carrot', text: 'Carrot' },
    { key: 'lettuce', text: 'Lettuce' },
];

interface ILoginViewProps { }

interface ILoginViewState extends IResponseResult {
    model: LoginModel,
    submitting: boolean;
}

export default class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {
    constructor(props: ILoginViewProps) {
        super(props);

        const model = new LoginModel();
        model.provider = AccountProvider.phone;
        model.countryCode = "855";
        model.phoneNo = "";
        model.password = "";

        this.state = {
            model: model,
            submitting: false,
            errorMessages: new LoginModel(),
        };
    }

    render() {
        return <div id={"login-form-wrapper"}>
            <div className="box" id={"login-form"}>
                {this.loginForm()}
            </div>
        </div>;
    }

    private loginForm = (): JSX.Element => {
        const { model, errorMessages, summaryType, summaryText, submitting } = this.state;
        const width12 = Utils.createPanelStyle(12);

        return <>
            <div className="ms-Grid">
                {model.provider === AccountProvider.phone && <>
                    <PhoneView showTelegram={false} defaultModel={model} errorMessages={errorMessages}
                        onChange={this.onPhoneChange} isRequired={true} />
                </>}

                <div className="ms-Grid-row">
                    <div className={width12}>
                        <TextField label="Password" defaultValue={model.password} required type="Password"
                            errorMessage={errorMessages.Password}
                            onChange={this.onPasswordChange}
                            onKeyDown={this.onPasswordKeyDown}
                        />
                    </div>
                </div>

                <div className="ms-Grid-row pt-20 pb-10">
                    <div className={width12}>
                        {!submitting && <div style={{ width: "100%" }}>
                            <PrimaryButton onClick={this.login} styles={{ root: { width: "100%" } }}>Login</PrimaryButton>
                        </div>}

                        {submitting && <div className="pt-15">
                            <ProgressIndicator label="Submitting" description="" />
                        </div>}
                    </div>
                </div>
            </div>
        </>;
    }

    private onPhoneChange = (phone: AccountPhoneModel) => {
        const { model } = this.state;
        model.countryCode = phone.countryCode;
        model.phoneNo = phone.phoneNo;
        this.setState({ model: model });
    }

    private onPasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const { model } = this.state;
        model.password = value ?? "";
        this.setState({ model: model });
    }

    private onPasswordKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            this.login();
        }
    }

    private login = () => {
        const { model } = this.state;

        if (model.provider === AccountProvider.email) {
            model.phoneNo = "";
            model.countryCode = "";
        } else {
            model.email = undefined;
        }

        this.setState({ submitting: true, errorMessages: {} });

        const request = AccountService.login(model);
        DataUtilities.onRequest(request, this.onLoginResult);
    }

    private onLoginResult = (result: IDataResponseResult) => {
        if (!result.errorMessages) result.errorMessages = {};
        var resultModel = result.data as ExtendedAuthorizationTokensInfo;

        if (result.status !== ResponseStatus.success) {
            this.setState({ submitting: false });
        } else {
            if (resultModel) {
                Authentication.setUserAuth(resultModel);
            }
        }

        if (resultModel) {
            if (resultModel.isConfirmed) {
                window.open("/", "_self");
                return;
            }
        }

        this.setState({ ...(result as IResponseResult) });
    }
}