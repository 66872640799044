import React from "react";
import * as $ from 'jquery';
import { IChoiceGroupOption } from '@fluentui/react';
import { AccountProvider, ExtendedAuthorizationTokensInfo } from "../../models/accounts/AccountTypes";
import { UserRolesInfo } from "../../models/accounts/UserRolesInfo";

export class Authentication {
    private static lastLoginRequestName = "lastloginrequest";
    private static selectedWarehouseName = "selectedWarehouse";
    private static userAuthName = "user_auth";

    public static setLastLoginRequest() {
        localStorage.setItem(this.lastLoginRequestName, Date.now().toString());
    }

    public static getLastLoginRequest() {
        const value = localStorage.getItem(this.lastLoginRequestName);

        return !value ? 0 : parseFloat(value);
    }

    public static setWarehouse(warehouseId: number) {
        localStorage.setItem(this.selectedWarehouseName, warehouseId.toString());
    }

    public static getSelectedWarehouseId() {
        const selectedId = localStorage.getItem(this.selectedWarehouseName);
        return selectedId ? parseInt(selectedId) : 0;
    }

    public static setUserAuth(account: ExtendedAuthorizationTokensInfo) {
        localStorage.setItem(this.userAuthName, JSON.stringify(account));
        this.setupAjaxAuthorization();
    }

    public static getUserAuth(): ExtendedAuthorizationTokensInfo {
        const value = localStorage.getItem(this.userAuthName);

        return !value ? undefined : JSON.parse(value);
    }

    public static getUserRoles(): UserRolesInfo {
        var info = this.getUserAuth();

        if (info) {
            return info.userRoles;
        }

        return new UserRolesInfo();
    }

    public static setupAjaxAuthorization() {
        const userAuth = this.getUserAuth();

        if (userAuth) {
            $.ajaxSetup({
                cache: false,
                headers: {
                    "Authorization": "Bearer " + userAuth.accessToken.token,
                    "WarehouseId": this.getSelectedWarehouseId().toString()
                }
            });
        }
    }

    public static getAccountProviderOptions() {
        const options: IChoiceGroupOption[] = [
            { key: AccountProvider.email.toString(), text: AccountProvider[AccountProvider.email] },
            { key: AccountProvider.phone.toString(), text: AccountProvider[AccountProvider.phone] }
        ];

        return options;
    }
}