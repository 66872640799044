import * as React from 'react';
import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { DataUtilities, comboOptionCallback } from '../common/DataUtilities';
import { IDataResponseResult } from '../../models/main/ResponseResult';
import { CustomerDropdownItem, DropdownItemType } from '../../models/others/OtherTypes';
import { CustomerService } from '../../services/CustomerService';

export type CustomerComboBoxCallback = (option: CustomerDropdownItem) => void;

export class CustomerUtilities {
    private static getCustomerOptionsResult(result: IDataResponseResult, callback: comboOptionCallback) {
        const data = result.data;

        if (data) {
            let list: IComboBoxOption[] = [];

            for (var i = 0; i < data.length; i++) {
                const item = data[i] as CustomerDropdownItem;
                const type = DropdownItemType[item.type].toString().toLocaleLowerCase();
                const dropDownType = type ? DataUtilities._comboBoxOptions[type] : SelectableOptionMenuItemType.Normal;
                list.push({ key: item.id, text: item.name, itemType: dropDownType, data: item });
            }

            callback(list);
        }
    }

    public static getCustomerOptions(search: string, userOnly: boolean, callback: comboOptionCallback) {
        var request = CustomerService.getCustomerDropdown(search, userOnly);
        DataUtilities.onRequest(request, (result: IDataResponseResult) =>
            this.getCustomerOptionsResult(result, callback));
    }

    public static getEmployeeOptions(callback: comboOptionCallback) {
        var request = CustomerService.getEmployeeDropdown();
        DataUtilities.onRequest(request, (result: IDataResponseResult) =>
            this.getCustomerOptionsResult(result, callback));
    }
}