import { PhotoCollageLayout, PhotoSize } from "../../models/photoCollages/PhotoCollageModel";
import { PhotoCollagePostModel } from "../../models/products/PictureModel";
import PhotoCollageBaseView, { IPhotoCollageBaseViewProps } from "./PhotoCollageBaseView";

export default class FourPhoto extends PhotoCollageBaseView {
    constructor(props: IPhotoCollageBaseViewProps) {
        super(props);
    }

    public render() {
        const { photos, layout } = this.props;

        const smallStyle: React.CSSProperties = {
            display: this._style.innerDisplay,
            paddingBottom: this._style.paddingBottom,
            paddingRight: this._style.paddingRight
        };

        if (layout === PhotoCollageLayout.allSquare) {
            return this.allSquares();
        }

        return <div style={{ display: "inline-block" }}>
            <div style={{
                float: this._style.outerFloat,
                paddingRight: this._style.paddingBottom,
                paddingBottom: this._style.paddingRight
            }}>
                {this.large(photos[0])}
            </div>
            <div style={{ float: this._style.innerFloat }}>
                <div style={smallStyle}>{this.small(photos[1])}</div>
                <div style={smallStyle}>{this.small(photos[2])}</div>
                <div style={smallStyle}>{this.small(photos[3])}</div>
            </div>
        </div>;
    }

    private allSquares = (): JSX.Element => {
        const { photoManager, photos } = this.props;
        const space = photoManager.dimension.getSpacing();

        return <div style={{ display: "inline-block" }}>
            <div style={{ paddingBottom: space }}>
                <div style={{ float: "left", display: "flex", paddingRight: space }}>
                    {photoManager.getSquare(photos[0], PhotoSize.half)}
                </div>
                <div style={{ display: "flex" }}>
                    {photoManager.getSquare(photos[1], PhotoSize.half)}
                </div>
            </div>

            <div>
                <div style={{ float: "left", display: "flex", paddingRight: space }}>
                    {photoManager.getSquare(photos[2], PhotoSize.half)}
                </div>
                <div style={{ display: "flex" }}>
                    {photoManager.getSquare(photos[3], PhotoSize.half)}
                </div>
            </div>
        </div>;
    }

    private large = (photo: PhotoCollagePostModel): JSX.Element => {
        const { photoManager } = this.props;

        return <>
            {photoManager.getRectangle(
                photo,
                this._layoutManager.isHalf() ? PhotoSize.half : PhotoSize.twoThird,
                PhotoSize.full,
                this._layoutManager.isTall()
            )}
        </>;
    }

    private small = (photo: PhotoCollagePostModel): JSX.Element => {
        const { photoManager } = this.props;
        return <>
            {photoManager.getRectangle(
                photo,
                this._layoutManager.isHalf() && this._layoutManager.isTall() ? PhotoSize.half : PhotoSize.oneThird,
                PhotoSize.oneThird,
                this._layoutManager.isTall()
            )}
        </>;
    }
}