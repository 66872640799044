import { MessageBarType } from '@fluentui/react';

export type onDataResponseResult = (response: IDataResponseResult) => void;

export interface IResponseResult {
    errorMessages: any;
    summaryText?: string;
    summaryType?: MessageBarType;
}

export interface IDataResponseResult extends IResponseResult {
    data?: any;
    status: ResponseStatus;
}

export enum ResponseStatus {
    fail = 0, success = 1, warning = 2
}

export class ResponseResult {
    public status: ResponseStatus = ResponseStatus.fail;
    public description: string = "";
    public returnedValue: any = null;
    public invalidData: { [key: string]: string; } = {};
}

export class IntResponseResult extends ResponseResult { }
export class StringResponseResult extends ResponseResult { }
export class BoolResponseResult extends ResponseResult { }