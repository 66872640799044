import { WarehouseKeyValueModel } from "../others/OtherTypes";
import { UserRolesInfo } from "./UserRolesInfo";

export enum AccountProvider {
    email = 0, phone = 1
}

export class AccountModel {
    public userId: string = "";
    public isConfirmed: boolean = false;
}

export class TokenResource {
    token: string = "";
    expiry: number = 0;
}

export class AuthorizationTokensInfo extends AccountModel {
    accessToken: TokenResource = new TokenResource();
    refreshToken: TokenResource = new TokenResource();
}

export class ExtendedAuthorizationTokensInfo extends AuthorizationTokensInfo {
    userRoles: UserRolesInfo = new UserRolesInfo();
}

export class AccountPhoneModel {
    countryCode: string = "";
    phoneNo: string = "";
    telegram: boolean = false;
}

export class LoginModel extends AccountPhoneModel {
    provider: AccountProvider = AccountProvider.phone;
    email?: string = undefined;
    password: string = "";
    rememberMe: boolean = false;
}