import { ContextualMenu, getId, Icon, IDragOptions, List, Modal, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import React from "react";
import { IDataResponseResult, IResponseResult, ResponseStatus } from "../../models/main/ResponseResult";
import { EditPictureModel, PictureModel, UploadPictureModel } from "../../models/products/PictureModel";
import { DataUtilities } from "../common/DataUtilities";
import { StyleUtils } from "../common/StyleUtils";
import { Utils } from "../common/Utils";
import PictureInputView from "./PictureInputView";

interface IEditPictureListViewProps {
    pictures: PictureModel[];
    edit: (pictures: EditPictureModel[]) => JQueryPromise<any>;
    onClose: (refresh: boolean) => void;
}

interface IEditPictureListViewState extends IResponseResult {
    pictures: EditPictureModel[];
    isSubmitting: boolean;
}

export default class EditPictureListView extends React.Component<IEditPictureListViewProps, IEditPictureListViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IEditPictureListViewProps) {
        super(props);

        this.state = {
            pictures: this.initialModels(props.pictures),
            errorMessages: {},
            isSubmitting: false
        }
    }

    private initialModels = (pictures: PictureModel[]) => {
        let inputModels = [];

        for (let i = 0; i < pictures.length; i++) {
            let picture = pictures[i];
            let inputModel = new EditPictureModel();
            inputModel.category = picture.category;
            inputModel.collageOrder = picture.collageOrder;
            inputModel.id = picture.id;
            inputModel.isPrimary = picture.isPrimary;
            inputModel.length = picture.length;
            inputModel.order = picture.order;
            inputModel.type = picture.type;

            inputModels.push(inputModel);
        }

        return inputModels;
    }

    render() {
        const { pictures, onClose } = this.props;
        const { isSubmitting } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={() => onClose(false)}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(800)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Edit Pictures</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={() => onClose(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    <div className="ms-Grid">
                        {this.printHeader()}
                        <List items={pictures} onRenderCell={this.onRenderRow} />
                    </div>

                    {!isSubmitting && <div className="ms-Grid-row center pv-20">
                        <PrimaryButton onClick={this.onSubmit}>Submit</PrimaryButton>
                    </div>}

                    {isSubmitting && <div className="pt-20">
                        <ProgressIndicator label="Submitting" description="" />
                    </div>}
                </div>
            </Modal>
        </>
    }

    private printHeader = () => {
        const column = Utils.createPanelStyle(2);

        return <div className="ms-Grid-row font-bold pv-10">
            <div className={column}></div>
            <div className={column}>Category</div>
            <div className={column}>Type</div>
            <div className={column}>Order</div>
            <div className={column}>Collage Order</div>
            <div className={column}>Is Primary</div>
        </div>
    }

    private onRenderRow = (picture?: PictureModel, index?: number) => {
        if (!picture) { return <></>; }

        return <PictureInputView picture={this.parseModel(picture)} onChange={this.onChange} />;
    }

    private parseModel = (picture: PictureModel): UploadPictureModel => {
        let model = new UploadPictureModel();
        model.bytes = picture.imageUrl;
        model.category = picture.category;
        model.collageOrder = picture.collageOrder;
        model.id = picture.id;
        model.isPrimary = picture.isPrimary;
        model.length = picture.length;
        model.order = picture.order;
        model.type = picture.type;

        return model;
    }

    private onChange = (newPicture: EditPictureModel) => {
        const { pictures } = this.state;

        let index = pictures.findIndex((picture: EditPictureModel) => picture.id === newPicture.id);

        if (index >= 0) pictures[index] = newPicture;

        this.setState({ pictures: pictures });
    }

    private onSubmit = () => {
        const { edit } = this.props;
        const { pictures } = this.state;

        if (pictures.length === 0) {
            alert("There is no file selected.");
            return;
        }

        const request = edit(pictures);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (response: IDataResponseResult) => {
        if (!response.errorMessages)
            response.errorMessages = {};

        const { onClose } = this.props;

        if (response.status === ResponseStatus.success) {
            onClose(true);
        }

        this.setState({ ...(response as IResponseResult), isSubmitting: false });
    }
}