export enum FileType {
    image,
    video,
    others,
    audio
}

export enum FileCategory {
    normal,
    feature,
    original
}

export class EditPictureModel {
    public id: number = 0;
    public category: FileCategory = FileCategory.normal;
    public type: FileType = FileType.image;
    public isPrimary: boolean = false;
    public order: number = 0;
    public collageOrder: number = 0;
    public length: number = 0;
}

export class UploadPictureModel extends EditPictureModel {
    public file?: any;
    public bytes?: any;
}

export class PictureModel extends EditPictureModel {
    imageUrl: string = "";
    fileName: string = "";
    filePath: string = "";
    content: string = "";
}

export class PhotoCollagePostModel {
    public id: number = 0;
    public imageUrl: string = "";
    public category: FileCategory = FileCategory.normal;
    public type: FileType = FileType.image;
    public isLike: boolean = false;
    public isFavorite: boolean = false;
    public likes: number = 0;
    public shares: number = 0;
}

export class PictureOrderModel {
    public id: number = 0;
    public order: number = 0;
}