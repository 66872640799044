import * as React from 'react';
import {
    IContextualMenuItem, CommandBar, IDropdownOption, Dropdown,
    SearchBox, SelectionMode, Icon, IColumn, ISelection
} from '@fluentui/react'
import { Filter, ComboboxFilter } from './GridFilters';
import { GridUtilities, GridActionInfo } from './GridUtilities';
import { GridData } from './GridData';
import { GridFilter } from './GridFilter';
import { PagedListModel, PagedRow, PageFilterModel, SortDirection } from '../../models/others/PageTypes';
import { DataUtilities, onResponseResultAction } from '../common/DataUtilities';
import { Utils } from '../common/Utils';
import { Authentication } from '../common/Authentication';
import { Pager } from './Pager';
import { Page } from './Page';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';

interface IGridColumn extends IColumn {
    isSelected: boolean;
}

export interface ISelectionList {
    selection: ISelection;
    selectionMode: SelectionMode;
    canSelect: 'all' | 'vowels';
}

export enum IconName {
    CustomList, Edit, Delete, PaymentCard, DeliveryTruck, Cancel, Save,
    PrintfaxPrinterFile, PDF, Preview, ViewDashboard, RectangleShape,
    Add, POISolid, Ringer, BlockedSolid, Blocked, PictureCenter, Mail,
    Devices3, Pinned, Unpin, Chat
}

export class RowActionInfo {
    public item: PagedRow | undefined;
    public action: IconName | undefined;
}

type GridDataCallback = (data: PagedListModel<any>) => void;
export type onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => void;
export type onRowAction = (item: RowActionInfo) => void;
export type onRender = (item: PagedRow) => JSX.Element;
export type emptyFunc = () => void;

interface IGridDataService {
    sortColumns?: string[];
    get: (model: PageFilterModel, onResult: onResponseResultAction) => void;
    getColumns: (onclick: onColumnClick, onRowAction: onRowAction) => IGridColumn[];
    getActions: () => GridActionInfo[];
    getMobileUIRow: (item: PagedRow) => JSX.Element;
}

interface IGridProps {
    defaultText?: string;
    onRef?: (obj: any) => void;
    service: IGridDataService;
    filters: Filter[];
    menuItems?: IContextualMenuItem[];
    onRowAction: (info: RowActionInfo) => void;
    onSelectionChange?: (data: PagedRow | PagedRow[]) => void;
    selectionMode?: SelectionMode;
    hideToolbar?: boolean;
    hideRefresh?: boolean;
    hideFilter?: boolean;
    hideSearch?: boolean;
    hidePager?: boolean;
    preMenu?: boolean;
    getCountByStatus?: (model: PageFilterModel) => void;
}

interface IGridState {
    requestModel: PageFilterModel;
    showFilter: boolean;
    dateFilterText: string;
    selectedSortOptions: string[];
    isLoading: boolean;
    data: PagedListModel<any>;
    columns: IGridColumn[];
    listhash: number;
    totalCount: number;
}

class Grid extends React.Component<IGridProps, IGridState> {
    private _searchBox: JSX.Element;
    private _commandBarItems: IContextualMenuItem[];
    //private _userRoles: UserRolesInfo;
    private _filterCallOutId: string;
    private _sortOptions: IDropdownOption[] = [];

    constructor(props: IGridProps) {
        super(props);

        this.state = {
            requestModel: this.initializeModel(),
            showFilter: false,
            dateFilterText: "",
            selectedSortOptions: [],
            isLoading: false,
            data: new PagedListModel(),
            columns: this.props.service.getColumns(this.onColumnClick, this.props.onRowAction),
            listhash: -1,
            totalCount: 0
        };

        const randomNumber = Math.floor(Math.random() * 100);
        this._filterCallOutId = "calloutButton-" + randomNumber;
        //this._userRoles = Authentication.getUserRoles();
        this._searchBox = this.createSearchBox(props.defaultText);
        this._commandBarItems = this.createCommandBarItems();
        this._sortOptions = this.getSortOptions();
    }

    private onColumnClick = (event: React.MouseEvent<HTMLElement>, column: IColumn) => {
        this.onSortChange(column.fieldName ?? "");
    }

    private initializeModel() {
        const { defaultText, filters } = this.props;
        const requestModel = new PageFilterModel();
        requestModel.pageNumber = 1;
        requestModel.pageSize = DataUtilities.DEFAULT_GRID_PAGE_SIZE;
        requestModel.sortDirection = SortDirection.ascending;
        requestModel.search = defaultText;

        var anyRequestModel = requestModel as any;

        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];
                anyRequestModel[filter.name] = filter.value;
            }
        }

        return requestModel;
    }

    public componentDidMount() {
        const { requestModel } = this.state;
        this.get(requestModel);
    }

    //*******************************************************
    //******************** UI *******************************
    //*******************************************************

    public render() {
        const { hideToolbar, hidePager } = this.props;
        const { showFilter, totalCount, listhash } = this.state;

        return <div>
            <div>{this.generateFilterTexts()}</div>
            {!hideToolbar && <div style={{ backgroundColor: "rgb(240, 240, 240)" }}>
                <CommandBar items={this._commandBarItems} />
            </div>}

            {showFilter && this.filterElement()}

            <div className="grid-content">
                {this.gridDataElement()}
            </div>

            {!hidePager && <>
                <Pager
                    totalCount={totalCount}
                    onChange={this.onPageChange}
                    key={listhash}
                />
            </>}
        </div>
    }

    private filterElement = () => {
        const { filters } = this.props;
        const { requestModel } = this.state;

        return <>
            <GridFilter
                filters={filters}
                id={this._filterCallOutId}
                model={requestModel}
                onCancel={() => this.setState({ showFilter: false })}
                onSubmit={this.onFilterSubmit} />
        </>;
    }

    private gridDataElement = () => {
        const { service, onRowAction, onSelectionChange, selectionMode } = this.props;
        const { data, columns } = this.state;

        return <>
            <GridData
                data={data}
                columns={columns}
                onRowAction={onRowAction}
                onSortChange={this.onSortChange}
                onSelectionChange={onSelectionChange}
                selectionMode={selectionMode} />
        </>;
    }

    private createSearchBox = (defaultText: string | undefined): JSX.Element => {
        return <SearchBox
            autoFocus={false}
            placeholder="Search"
            value={defaultText}
            className="searchbox"
            onSearch={newValue => this.onSearchTextChange(newValue)}
            onClear={() => this.onSearchTextChange("")}
            onChange={(event: React.FormEvent<HTMLInputElement> | undefined, value?: string) => {
                console.log("onChange: " + value);
            }}
        />
    }

    private createCommandBarItems = (): IContextualMenuItem[] => {
        const { hideRefresh, hideFilter, menuItems, preMenu } = this.props;

        const filterMenu = {
            key: "Filter",
            name: "Filter",
            id: this._filterCallOutId,
            iconProps: { iconName: "Filter" },
            onClick: () => {
                this.setState({ showFilter: true });
            }
        };

        const refreshMenu = {
            key: "Refresh",
            name: "Refresh",
            iconProps: { iconName: "Refresh" },
            onClick: () => {
                this.get(this.state.requestModel);
            }
        };

        let list: IContextualMenuItem[] = [];

        if (preMenu) {
            if (menuItems) list = list.concat(menuItems);
            if (!hideRefresh) list.push(refreshMenu);
            if (!hideFilter) list.push(filterMenu);
        } else {
            if (!hideRefresh) list.push(refreshMenu);
            if (!hideFilter) list.push(filterMenu);
            if (menuItems) list = list.concat(menuItems);
        }

        return list;
    }

    //*******************************************************
    //******************* Methods ***************************
    //*******************************************************

    private get = (requestModel: PageFilterModel) => {
        if (!this.state.isLoading && this.props.service) {
            this.setState({ isLoading: true });
            this.props.service.get(requestModel, this.onResult);
        }
    }

    private onResult = (result: IDataResponseResult) => {
        if (result.status !== ResponseStatus.fail) {
            this.setState({ data: result.data, totalCount: result.data.totalCount });
            if (this.state.totalCount !== result.data.totalCount) {
                this.setState({ listhash: Utils.getRandomKey() });
            }
        }

        this.setState({ isLoading: false });
    }

    public refreshData = () => {
        const { requestModel } = this.state;
        this.get(requestModel);
    }

    private onPageChange = (page: Page) => {
        if (page) {
            let requestModel = this.state.requestModel;
            requestModel.pageNumber = page.pageNumber;
            const resetPageNumber = requestModel.pageSize !== page.pageSize;
            requestModel.pageSize = page.pageSize;
            this.updateRequestModel(requestModel, resetPageNumber);
        }
    }

    private onSortChange = (sortBy: string) => {
        let requestModel = this.state.requestModel;
        let direction = sortBy === requestModel.sortBy && requestModel.sortDirection === SortDirection.ascending ?
            SortDirection.descending :
            SortDirection.ascending;
        requestModel.sortBy = sortBy;
        requestModel.sortDirection = direction;
        this.updateRequestModel(requestModel);
    }

    private onMobileSortChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const { requestModel } = this.state;

        if (option) {
            requestModel.sortBy = option.key as string;
        }

        this.updateRequestModel(requestModel);
    }

    private onSortDirectionChange = () => {
        const { requestModel } = this.state;

        if (requestModel.sortDirection === SortDirection.descending)
            requestModel.sortDirection = SortDirection.ascending;
        else
            requestModel.sortDirection = SortDirection.descending;

        this.updateRequestModel(requestModel);
    }

    private getSortOptions = () => {
        const columns = this.props.service.sortColumns;
        const list = [{ key: "", text: "" }];

        if (columns && !Utils.isArrayNullOrEmpty(columns)) {
            for (let i = 0; i < columns.length; i++) {
                list.push({ key: columns[i], text: columns[i] });
            }
        }

        return list;
    }

    private onSearchTextChange = (newValue: string) => {
        const { requestModel } = this.state;
        requestModel.search = newValue;
        this.updateRequestModel(requestModel, true, true);
    }

    private onRemoveFilterText = (name: string) => {
        this.onRemoveFilterTexts([name]);
    }

    private onRemoveFilterTexts = (names: string[]) => {
        if (!Utils.isArrayNullOrEmpty(names)) {
            let requestModel = this.state.requestModel as any;

            for (var i = 0; i < names.length; i++) {
                requestModel[names[i]] = undefined;
            }

            this.updateRequestModel(requestModel, true, true);
            this.generateFilterTexts();
        }
    }

    private onFilterSubmit = (model: PageFilterModel) => {
        this.setState({ showFilter: false }, () => {
            this.updateRequestModel(model, true, true);
            this.generateFilterTexts();
        });
    }

    public updateRequestModel = (requestModel: PageFilterModel, resetPageNumber: boolean = true,
        requestCount: boolean = false) => {
        const { getCountByStatus } = this.props;

        if (resetPageNumber) requestModel.pageNumber = 1;
        this.setState({ requestModel: requestModel });
        this.get(requestModel);
        if (requestCount && getCountByStatus) getCountByStatus(requestModel);
    }

    public getRequestModel = () => {
        return this.state.requestModel;
    }

    private generateFilterTexts = () => {
        let list: JSX.Element[] = [];
        const model = this.state.requestModel as any;
        const filters = this.props.filters;

        if (!Utils.isNull(model.StartDate) || !Utils.isNull(model.EndDate)) {
            let text = Utils.formatShortDate(model.StartDate) + " - " + Utils.formatShortDate(model.EndDate);
            list.push(GridUtilities.createFilterTextElement(text, () => {
                this.onRemoveFilterTexts(["startDate", "endDate"]);
            }));
        }

        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                let valueText = model[filters[i].name];

                if (filters[i] instanceof ComboboxFilter) valueText = (filters[i] as ComboboxFilter).text;

                const text = filters[i].getText(valueText);

                if (text) {
                    list.push(GridUtilities.createFilterTextElement(text, () => {
                        this.onRemoveFilterText(filters[i].name);
                        if (filters[i] instanceof ComboboxFilter) (filters[i] as ComboboxFilter).text = "";
                    }));
                }
            }
        }

        return list;
    }
}

export { Grid };
export type { IGridDataService, IGridColumn, GridDataCallback };