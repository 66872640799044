import { baseUrl } from "../../constants/url.constant";
import { PhotoDimension, PhotoSize } from "../../models/photoCollages/PhotoCollageModel";
import { FileType, PhotoCollagePostModel } from "../../models/products/PictureModel";
import { Utils } from "../common/Utils";

export class PhotoShapeManager {
    public width: number
    public dimension: PhotoDimension

    constructor(width: number) {
        this.width = width;
        this.dimension = new PhotoDimension(width);
    }

    public getSquare = (photo: PhotoCollagePostModel, size: PhotoSize): JSX.Element => {
        return this.getRectangle(photo, size, size);
    }

    public getRectangle = (photo: PhotoCollagePostModel, width: PhotoSize, height: PhotoSize, tall: boolean = true): JSX.Element => {
        const w = tall ? width : height;
        const h = tall ? height : width;
        const spaceCount = tall && width === PhotoSize.oneThird ? 1 : 2;

        var url = "";

        if (photo.type === FileType.video) {
            url = "/dist/vendor/video.png";
        } else if (photo.type === FileType.others) {
            url = "/dist/vendor/file.png";
        } else {
            url = photo.imageUrl;
        }

        if (Utils.isValidUrl(baseUrl + url)) url = baseUrl + url;

        return <>
            <img
                className="center-cropped"
                width={this.getSizeValue(w, spaceCount)}
                height={this.getSizeValue(h)}
                src={url} />
        </>;
    }

    private getSizeValue = (size: PhotoSize, spaceCount: number = 2): number => {
        if (size === PhotoSize.oneThird) return this.dimension.getOneThird(spaceCount);
        else if (size === PhotoSize.half) return this.dimension.getHalf();
        else if (size === PhotoSize.twoThird) return this.dimension.getTwoThird();
        else if (size === PhotoSize.full) return this.dimension.width;
        else if (size === PhotoSize.extraFull) return this.dimension.getExtraWidth();

        return this.dimension.width;
    }
}