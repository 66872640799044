import React from "react";
import AdvertisementView from "../../components/advertisements/AdvertisementView";

const Advertisement: React.FC = () => {
    return (
        <div className="ph-10">
            <AdvertisementView />
        </div>
    );
};

export default Advertisement;