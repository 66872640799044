export interface PagedRow { }

export enum SortDirection {
    ascending = 0, descending = 1
}
export class PageFilterModel {
    public startDate?: Date;
    public endDate?: Date;
    public search: string | undefined;
    public pageNumber: number = 0;
    public pageSize: number = 0;
    public sortByKey: number = 0;
    public sortByText: string = "";
    public sortBy: string | undefined;
    public sortDirection: SortDirection = SortDirection.ascending;
    public pagingDisabled: boolean = false;
}

export class PagedListModel<T> {
    public items: T[] = [];
    public totalCount: number = 0;
}