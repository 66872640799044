import { IDropdownOption } from "@fluentui/react";
import { OrderRejectReason, OrderStatus, OrderTracking } from "../../models/orders/OrderModel";
import { SortDirection } from "../../models/others/PageTypes";
import { Utils } from "../common/Utils";

export class OrderUtilities {
    public static getStatusOptions(): IDropdownOption[] {
        const list = [
            { key: OrderStatus.request, text: Utils.toCapital(OrderStatus[OrderStatus.request]) },
            { key: OrderStatus.quote, text: Utils.toCapital(OrderStatus[OrderStatus.quote]) },
            { key: OrderStatus.orderPending, text: Utils.toCapital(OrderStatus[OrderStatus.orderPending]) },
            { key: OrderStatus.orderApproved, text: Utils.toCapital(OrderStatus[OrderStatus.orderApproved]) },
            { key: OrderStatus.completed, text: Utils.toCapital(OrderStatus[OrderStatus.completed]) },
            { key: OrderStatus.declined, text: Utils.toCapital(OrderStatus[OrderStatus.declined]) },
            { key: OrderStatus.canceled, text: Utils.toCapital(OrderStatus[OrderStatus.canceled]) }
        ];

        return list;
    }

    public static getReasonOptions() {
        const list = [
            { key: OrderRejectReason.none, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.none]) },
            { key: OrderRejectReason.changeMind, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.changeMind]) },
            { key: OrderRejectReason.damaged, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.damaged]) },
            { key: OrderRejectReason.outOfStock, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.outOfStock]) },
            { key: OrderRejectReason.priceTooHigh, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.priceTooHigh]) },
            { key: OrderRejectReason.waitToLong, text: Utils.splitCapitalText(OrderRejectReason[OrderRejectReason.waitToLong]) }
        ];

        return list;
    }

    public static getTrackingOptions() {
        const list = [
            { key: OrderTracking.none, text: Utils.splitCapitalText(OrderTracking[OrderTracking.none]) },
            { key: OrderTracking.placedOnline, text: Utils.splitCapitalText(OrderTracking[OrderTracking.placedOnline]) },
            { key: OrderTracking.inForeignStock, text: Utils.splitCapitalText(OrderTracking[OrderTracking.inForeignStock]) },
            { key: OrderTracking.shippedToDestination, text: Utils.splitCapitalText(OrderTracking[OrderTracking.shippedToDestination]) },
            { key: OrderTracking.arrivedDestination, text: Utils.splitCapitalText(OrderTracking[OrderTracking.arrivedDestination]) },
            { key: OrderTracking.readyForDelivery, text: Utils.splitCapitalText(OrderTracking[OrderTracking.readyForDelivery]) },
            { key: OrderTracking.outForDelivery, text: Utils.splitCapitalText(OrderTracking[OrderTracking.outForDelivery]) },
            { key: OrderTracking.delivered, text: Utils.splitCapitalText(OrderTracking[OrderTracking.delivered]) }
        ];

        return list;
    }

    public static getSortOptions() {
        const list = [
            { key: 1, name: "Date", direction: SortDirection.descending, text: "Date" },
            { key: 2, name: "Status", direction: SortDirection.ascending, text: "Status" },
            { key: 3, name: "Reason", direction: SortDirection.ascending, text: "Reason" },
            { key: 4, name: "Tracking", direction: SortDirection.ascending, text: "Tracking Asc" },
            { key: 5, name: "Tracking", direction: SortDirection.descending, text: "Tracking Desc" }
        ];

        return list;
    }

    public static isPendingStatus(status: OrderStatus) {
        return status == OrderStatus.request || status == OrderStatus.quote || status == OrderStatus.orderPending;
    }

    public static isOrderStatus(status: OrderStatus) {
        return status == OrderStatus.orderPending || status == OrderStatus.orderApproved || status == OrderStatus.completed;
    }
}