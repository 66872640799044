import * as React from 'react';
import { TextField, IDropdownOption, Dropdown } from '@fluentui/react';
import { AccountPhoneModel } from '../../models/accounts/AccountTypes';
import { Utils } from '../common/Utils';

interface IPhoneViewProps {
    showTelegram: boolean;
    defaultModel?: AccountPhoneModel;
    isRequired?: boolean;
    errorMessages: AccountPhoneModel;
    onChange: (model: AccountPhoneModel) => void;
}

interface IPhoneViewState {
    model: AccountPhoneModel;
}

export class PhoneView extends React.Component<IPhoneViewProps, IPhoneViewState> {
    private countryCodeOptions: IDropdownOption[] = [
        { key: '855', text: '(+855)', data: { icon: 'Cambodia.jpg' } },
        { key: '1', text: '(+1)', data: { icon: 'USA.jpg' } }
    ];

    constructor(props: IPhoneViewProps) {
        super(props);

        const model = new AccountPhoneModel;

        if (props.defaultModel) {
            model.countryCode = props.defaultModel.countryCode;
            model.phoneNo = props.defaultModel.phoneNo;
            model.telegram = props.defaultModel.telegram;
        }

        this.state = {
            model: model
        }
    }

    private initialModel(defaultModel?: AccountPhoneModel) {
        let model = new AccountPhoneModel();

        if (defaultModel) {
            model = defaultModel;
        }

        if (Utils.isNullOrEmpty(model.countryCode)) model.countryCode = "855";
        if (Utils.isNullOrEmpty(model.phoneNo)) model.phoneNo = "";

        return model;
    }

    public render() {
        const { errorMessages, isRequired } = this.props;
        const { model } = this.state;
        const width5 = Utils.createPanelStyle(5);
        const width7 = Utils.createPanelStyle(7);

        return <div className="ms-Grid-row">
            <div className={width5}>
                <Dropdown
                    label="Country Code"
                    defaultSelectedKey={model.countryCode}
                    options={this.countryCodeOptions}
                    required={isRequired}
                    onChange={this.onCountryChange}
                />
            </div>
            <div className={width7}>
                <TextField label="Phone #" value={model.phoneNo} required={isRequired}
                    onChange={this.onPhoneNoChange}
                    errorMessage={errorMessages.phoneNo} />
            </div>
        </div>;
    }

    private onCountryChange = (ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const { model } = this.state;
        model.countryCode = option ? option.key.toString() : "";
        this.setState({ model: model });
        this.props.onChange(model);
    }

    private onPhoneNoChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const { model } = this.state;
        model.phoneNo = value ?? "";
        this.setState({ model: model });
        this.props.onChange(model);
    }
}