import { ContextualMenu, getId, Icon, IDragOptions, List, Modal, PrimaryButton, ProgressIndicator, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { IDataResponseResult, IResponseResult, ResponseStatus } from "../../models/main/ResponseResult";
import { FileCategory, FileType, UploadPictureModel } from "../../models/products/PictureModel";
import { DataUtilities } from "../common/DataUtilities";
import { StyleUtils } from "../common/StyleUtils";
import { Utils } from "../common/Utils";
import PictureInputView from "./PictureInputView";

interface IAddPictureListViewProps {
    create: (model: FormData) => JQueryPromise<any>;
    onClose: (refresh: boolean) => void;
}

interface IAddPictureListViewState extends IResponseResult {
    pictures: UploadPictureModel[];
    isSubmitting: boolean;
    isLoading: boolean;
    listHash: number;
}

export default class AddPictureListView extends React.Component<IAddPictureListViewProps, IAddPictureListViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IAddPictureListViewProps) {
        super(props);

        this.state = {
            pictures: [],
            errorMessages: {},
            isSubmitting: false,
            isLoading: false,
            listHash: -1
        }
    }

    render() {
        const { onClose } = this.props;
        const { pictures, isSubmitting, isLoading, listHash } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={() => onClose(false)}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(800)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Add Pictures</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={() => onClose(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    <div className="pv-20 center">
                        <input id="uploadFile" name="uploadFile" type="file"
                            onChange={(e) => this.onLoadFiles(e.target)} multiple />
                    </div>

                    {isLoading && <Spinner size={SpinnerSize.large} />}

                    <div className="ms-Grid">
                        {this.printHeader()}
                        <List items={pictures} onRenderCell={this.onRenderRow} key={listHash} />
                    </div>

                    {!isSubmitting && <div className="ms-Grid-row center pv-20">
                        <PrimaryButton onClick={this.onSubmit}>Submit</PrimaryButton>
                    </div>}

                    {isSubmitting && <div className="pt-20">
                        <ProgressIndicator label="Submitting" description="" />
                    </div>}
                </div>
            </Modal>
        </>
    }

    private printHeader = () => {
        const column = Utils.createPanelStyle(2);

        return <div className="ms-Grid-row font-bold pv-10">
            <div className={column}></div>
            <div className={column}>Category</div>
            <div className={column}>Type</div>
            <div className={column}>Order</div>
            <div className={column}>Collage Order</div>
            <div className={column}>Is Primary</div>
        </div>
    }

    private onRenderRow(picture?: UploadPictureModel, index?: number) {
        if (!picture) { return <></>; }

        return <PictureInputView picture={picture} />;
    }

    private onLoadFiles = (event: HTMLInputElement) => {
        this.setState({ pictures: [], isLoading: true });

        if (event.files && event.files.length > 0) {
            for (var i = 0; i < event.files.length; i++) {
                this.loadDisplayPicture(event.files[i], i, event.files.length);
            }
        }
    }

    private loadDisplayPicture = (file: File, index: number, length: number) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let model = new UploadPictureModel();
            model.type = FileType.image;
            model.category = FileCategory.normal;
            model.collageOrder = 99;
            model.bytes = [reader.result];
            model.file = file;

            const { pictures } = this.state;
            pictures.push(model);

            this.setState({ pictures: pictures });

            if (index === length - 1) {
                this.setState({ listHash: Utils.getRandomKey(), isLoading: false });
            }
        };
    }

    private onSubmit = () => {
        const { create } = this.props;
        const { pictures } = this.state;

        if (pictures.length === 0) {
            alert("There is no file selected.");
            return;
        }

        var formData = this.buildFormData();
        let request = create(formData);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (response: IDataResponseResult) => {
        if (!response.errorMessages)
            response.errorMessages = {};

        const { onClose } = this.props;

        if (response.status === ResponseStatus.success) {
            onClose(true);
        }

        this.setState({ ...(response as IResponseResult), isSubmitting: false });
    }

    private buildFormData() {
        let pictures = this.state.pictures as any[];
        var requestForm = new FormData();

        for (let i = 0; i < pictures.length; i++) {
            const picture = pictures[i];
            const prefix = "[" + i + "]";
            Utils.buildFormDataProperties(requestForm, picture, [], prefix);
        }

        return requestForm;
    }
}