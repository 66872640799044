import "./home.scss";
import { useNavigate } from "react-router-dom";

const PricePolicy = () => {
    const redirect = useNavigate();
    return (
        <div className="home-page-container ph-10 pt-20">
            <div className="home-page-body">
                <h1 className="pb-20">Pricing Policy</h1>
                <h3 className="pb-20">Effective as of January 2024</h3>
                <div className="policy">
                    <h3>Important Note About Pricing</h3>
                    <p>Prices of products listed on our website are subject to change without notice. We do our
                        best to correct any errors, inaccuracies or omissions we discover. We have the right to
                        revoke any stated offer, including after an order has been submitted and your payment method
                        charged. If your payment method has already been charged for the purchase and we elect to
                        cancel your order, we will issue a credit to your payment account(s) in the amounts of the charge.</p>

                    <h3>Returns & Exchanges</h3>
                    <p>All sale are final, no return or exchange accepted without written approval from us.</p>
                </div>
            </div>
        </div>
    );
};

export default PricePolicy;