import React from "react";
import {
    PhotoCollageLayout, PhotoCollageLayoutManager, PhotoCollageStyle
} from "../../models/photoCollages/PhotoCollageModel";
import { PhotoCollagePostModel } from "../../models/products/PictureModel";
import { PhotoShapeManager } from "./PhotoShapeManager";

export interface IPhotoCollageBaseViewProps {
    photos: PhotoCollagePostModel[];
    photoManager: PhotoShapeManager;
    layout: PhotoCollageLayout;
}

interface IPhotoCollageBaseViewState { }

export default class PhotoCollageBaseView
    extends React.Component<IPhotoCollageBaseViewProps, IPhotoCollageBaseViewState> {
    protected _layoutManager: PhotoCollageLayoutManager;
    protected _style: PhotoCollageStyle;

    constructor(props: IPhotoCollageBaseViewProps) {
        super(props);

        this.state = {};

        this._layoutManager = new PhotoCollageLayoutManager(props.layout);

        const space = props.photoManager.dimension.getSpacing();
        const isTall = this._layoutManager.isTall();
        this._style = new PhotoCollageStyle(space, isTall);
    }
}