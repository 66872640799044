import { Checkbox, Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import React from "react";
import { IResponseResult } from "../../models/main/ResponseResult";
import { EditPictureModel, UploadPictureModel } from "../../models/products/PictureModel";
import { DataUtilities } from "../common/DataUtilities";
import { Utils } from "../common/Utils";
import { ProductUtilities } from "../products/ProductUtilities";

interface IPictureInputViewProps {
    picture: UploadPictureModel;
    onChange?: (picture: EditPictureModel) => void;
}

interface IPictureInputViewState extends IResponseResult {
    model: EditPictureModel;
}

export default class PictureInputView extends React.Component<IPictureInputViewProps, IPictureInputViewState> {
    constructor(props: IPictureInputViewProps) {
        super(props);

        this.state = {
            model: this.initializeModel(props.picture),
            errorMessages: new EditPictureModel()
        }
    }

    private initializeModel = (picture: UploadPictureModel) => {
        let model = new EditPictureModel();
        model.category = picture.category;
        model.collageOrder = picture.collageOrder;
        model.id = picture.id;
        model.isPrimary = picture.isPrimary;
        model.length = picture.length;
        model.order = picture.order;
        model.type = picture.type;

        return model;
    }

    render() {
        const { picture } = this.props;
        const { model, errorMessages } = this.state;
        const column = Utils.createPanelStyle(2);

        return <div className="ms-Grid-row pv-10">
            <div className={column}>
                {ProductUtilities.getMiniPicture(picture.type, picture.bytes, "", () => { }, 90)}
            </div>
            <div className={column}>
                <Dropdown
                    label=""
                    selectedKey={model.category}
                    options={DataUtilities.getFileCategoryOptions()}
                    errorMessage={errorMessages["category"]}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                        this.onValueChange("category", option ? option.key : undefined)} />
            </div>
            <div className={column}>
                <Dropdown
                    label=""
                    selectedKey={model.type}
                    options={DataUtilities.getFileTypeOptions()}
                    errorMessage={errorMessages["type"]}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
                        this.onValueChange("type", option ? option.key : undefined)} />
            </div>
            <div className={column}>
                <TextField label="" value={model.order.toString()}
                    errorMessage={errorMessages["order"]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("order", newValue)} />
            </div>
            <div className={column}>
                <TextField label="" value={model.collageOrder.toString()}
                    errorMessage={errorMessages["collageOrder"]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("collageOrder", newValue)} />
            </div>
            <div className={column + " pt-5"}>
                <Checkbox label="" checked={model.isPrimary}
                    onChange={(event?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) =>
                        this.onValueChange("isPrimary", checked)} />
            </div>
        </div>;
    }

    private onValueChange = (name: string, value: any) => {
        const { onChange } = this.props;
        let model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });

        if (onChange) onChange(model);
    }
}