import { DefaultButton, ProgressIndicator, TextField } from '@fluentui/react';
import * as React from 'react';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { ChangeWebsiteOrderModel } from '../../models/orders/OrderModel';
import { ShortWebsiteModel } from '../../models/products/WebsiteModel';
import { OrderService } from '../../services/OrderService';
import { Authentication } from '../common/Authentication';
import { DataUtilities } from '../common/DataUtilities';
import { PopUpType, WebsitePicker } from '../products/WebsitePicker';

interface IChangeOrderWebsiteViewProps {
    defaultModel: ChangeWebsiteOrderModel;
    options: ShortWebsiteModel[];
    onCompleted: () => void;
}

interface IChangeOrderWebsiteViewState {
    model: ChangeWebsiteOrderModel;
    isSubmitting: boolean;
    userRoles: UserRolesInfo;
}

export class ChangeOrderWebsiteView extends React.Component<IChangeOrderWebsiteViewProps, IChangeOrderWebsiteViewState> {
    constructor(props: IChangeOrderWebsiteViewProps) {
        super(props);

        const model = new ChangeWebsiteOrderModel();
        model.id = props.defaultModel.id;
        model.websiteId = props.defaultModel.websiteId;
        model.earningPointRate = props.defaultModel.earningPointRate;

        this.state = {
            model: model,
            isSubmitting: false,
            userRoles: Authentication.getUserRoles()
        };
    }

    render() {
        const { options } = this.props;
        const { model, userRoles, isSubmitting } = this.state;

        return <>
            <WebsitePicker
                value={model.websiteId}
                type={PopUpType.Modal}
                onChange={(value?: number) => this.onValueChange("websiteId", value)}
                options={options} />

            <div className="pt-10">
                <TextField label="Earning Points" value={model.earningPointRate.toString()}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("earningPointRate", newValue)} />
            </div>

            {userRoles.isSalesSupervisor && !isSubmitting && <div className="pt-15 right">
                <DefaultButton onClick={this.submit}>Save Change</DefaultButton>
            </div>}

            {isSubmitting && <div className="pt-15">
                <ProgressIndicator label="Submitting" description="" />
            </div>}
        </>;
    }

    private onValueChange = (name: string, value?: any) => {
        const model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }

    private submit = () => {
        const { defaultModel } = this.props;
        const { model } = this.state;

        model.id = defaultModel.id;

        let request = OrderService.changeWebiste(model);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (result: IDataResponseResult) => {
        const { onCompleted } = this.props;

        if (result.status === ResponseStatus.success && onCompleted) {
            onCompleted();
        }

        this.setState({ isSubmitting: false });
    }
}