import { PhotoSize } from "../../models/photoCollages/PhotoCollageModel";
import { PhotoCollagePostModel } from "../../models/products/PictureModel";
import PhotoCollageBaseView, { IPhotoCollageBaseViewProps } from "./PhotoCollageBaseView";

export default class FivePhoto extends PhotoCollageBaseView {
    constructor(props: IPhotoCollageBaseViewProps) {
        super(props);
    }

    public render() {
        const { photos } = this.props;

        const smallStyle: React.CSSProperties = {
            display: this._style.innerDisplay,
            paddingBottom: this._style.paddingBottom,
            paddingRight: this._style.paddingRight
        };

        return <div style={{ display: "inline-block" }}>
            <div style={{
                float: this._style.outerFloat,
                paddingRight: this._style.paddingBottom,
                paddingBottom: this._style.paddingRight
            }}>
                <div style={smallStyle}>{this.large(photos[0])}</div>
                <div style={smallStyle}>{this.large(photos[1])}</div>
            </div>
            <div style={{ float: this._style.innerFloat }}>
                <div style={smallStyle}>{this.small(photos[2])}</div>
                <div style={smallStyle}>{this.small(photos[3])}</div>
                <div style={smallStyle}>{this.small(photos[4])}</div>
            </div>
        </div>;
    }

    private large = (photo: PhotoCollagePostModel): JSX.Element => {
        const { photoManager } = this.props;

        return <>
            {photoManager.getSquare(photo, PhotoSize.half)}
        </>;
    }

    private small = (photo: PhotoCollagePostModel): JSX.Element => {
        const { photoManager } = this.props;
        const isTall = this._layoutManager.isTall();

        return <>
            {photoManager.getRectangle(
                photo,
                isTall ? PhotoSize.half : PhotoSize.oneThird,
                PhotoSize.oneThird,
                isTall
            )}
        </>;
    }
}