import { DefaultService } from './DefaultService';
import * as $ from 'jquery';
import { LoginModel } from '../models/accounts/AccountTypes';
import { apiBaseUrl } from '../constants/url.constant';

export class AccountService extends DefaultService {
    private static controller: string = "Account";

    public static RouteLogout = () => `/${this.controller}/logout`;
    public static logout(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteLogout(), {
            type: 'get',
            contentType: 'text/html; charset=utf-8'
        });
    }

    public static RouteLogin = () => `/${this.controller}/login`;
    public static login(model: LoginModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteLogin(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }
}