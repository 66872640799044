import { DefaultButton, Dropdown, IDropdownOption, ProgressIndicator, TextField } from '@fluentui/react';
import * as React from 'react';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { ApproveOrderModel, OrderModel, OrderStatus } from '../../models/orders/OrderModel';
import { OrderService } from '../../services/OrderService';
import { Authentication } from '../common/Authentication';
import { DataUtilities } from '../common/DataUtilities';
import { OrderUtilities } from './OrderUtilities';

interface IApproveOrderViewProps {
    item: OrderModel;
    onCompleted: () => void;
}

interface IApproveOrderViewState {
    model: ApproveOrderModel;
    isSubmitting: boolean;
    optionsHash: number;
    userRoles: UserRolesInfo
}

export default class ApproveOrderView extends React.Component<IApproveOrderViewProps, IApproveOrderViewState> {
    constructor(props: IApproveOrderViewProps) {
        super(props);

        this.state = {
            model: this.initialApprovalModel(props.item),
            isSubmitting: false,
            optionsHash: -1,
            userRoles: Authentication.getUserRoles()
        }
    }

    private initialApprovalModel(item: OrderModel) {
        const model = new ApproveOrderModel();
        model.id = item.id;
        model.status = item.status;
        model.reason = item.reason;
        model.tracking = item.tracking;
        model.note = item.note;

        return model;
    }

    render() {
        const { item } = this.props;
        const { model, userRoles, isSubmitting } = this.state;

        return <>
            <div>
                <Dropdown
                    label="Status"
                    selectedKey={model.status}
                    options={OrderUtilities.getStatusOptions()}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                        this.onValueChange("status", option ? option.key : OrderStatus.request);
                    }} />
            </div>

            <div className="pt-10">
                <Dropdown
                    label="Reason"
                    selectedKey={model.reason}
                    options={OrderUtilities.getReasonOptions()}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                        this.onValueChange("reason", option ? option.key : undefined);
                    }} />
            </div>

            <div className="pt-10">
                <Dropdown
                    label="Tracking"
                    selectedKey={model.tracking}
                    styles={{ dropdown: { border: "1px solid darkgray" } }}
                    options={OrderUtilities.getTrackingOptions()}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                        this.onValueChange("tracking", option ? option.key : undefined);
                    }}
                />
            </div>

            <div className="pt-10">
                <TextField label="Note" value={model.note} multiline rows={2}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                        this.onValueChange("note", newValue)} />
            </div>

            {userRoles.isSalesSupervisor && !isSubmitting && <div className="pt-15 right">
                <DefaultButton onClick={this.submit}>Save Change</DefaultButton>
            </div>}

            {isSubmitting && <div className="pt-15">
                <ProgressIndicator label="Submitting" description="" />
            </div>}
        </>;
    }

    private submit = () => {
        const { model } = this.state;
        let request = OrderService.approve(model);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (result: IDataResponseResult) => {
        const { onCompleted } = this.props;

        if (result.status === ResponseStatus.success && onCompleted) {
            onCompleted();
        }

        this.setState({ isSubmitting: false });
    }

    private onValueChange = (name: string, value: any) => {
        let model = this.state.model as any;
        model[name] = value;

        this.setState({ model: model });
    }
}