import * as React from 'react';
import { StyleUtils } from '../common/StyleUtils';
import { Utils } from '../common/Utils';
import { DataUtilities } from '../common/DataUtilities';
import {
    ContextualMenu, DefaultButton, getId, getTheme, Icon, IDragOptions,
    Link, List, Modal, Panel, PanelType, SearchBox
} from '@fluentui/react';
import { ShortWebsiteModel } from '../../models/products/WebsiteModel';

export enum PopUpType {
    Modal,
    Panel
}

interface IWebsitePickerProps {
    value?: number;
    onChange: (value?: number) => void;
    errorMessage?: string;
    options: ShortWebsiteModel[];
    type: PopUpType;
}

interface IWebsitePickerState {
    value?: number;
    text?: string;
    showMenu: boolean;
    filteredOptions: ShortWebsiteModel[];
    listHash: number;
    recentItems: any[];
}

export class WebsitePicker extends React.Component<IWebsitePickerProps, IWebsitePickerState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };
    private _searchBox: JSX.Element;

    constructor(props: IWebsitePickerProps) {
        super(props);
        this.state = {
            value: props.value,
            text: this.getText(props.value),
            showMenu: false,
            filteredOptions: props.options,
            listHash: -1,
            recentItems: []
        };

        this._searchBox = this.createSearchBox();
    }

    componentWillReceiveProps(newProps: IWebsitePickerProps) {
        if (this.props.value !== newProps.value)
            this.setState({ text: this.getText(newProps.value) });
    }

    private createSearchBox = (): JSX.Element => {
        return <SearchBox
            autoFocus={false}
            placeholder="Search"
            onSearch={newValue => this.onTextChange(newValue)}
            onClear={() => this.onTextChange("")}
            onChange={(event?: React.FormEvent<HTMLInputElement>, value?: string) => { }}
            styles={{ root: { width: "100%", maxWidth: 500 } }}
            className="pb-10"
            underlined={true}
        />
    }

    render() {
        const { type } = this.props;
        const { text } = this.state;
        return <>
            <div className="pb-5">
                <label className="pr-5 bold">Website</label>
                <Icon className="pointer" iconName="cancel" onClick={this.onClear} />
            </div>
            <div className="brand-dropdown" onClick={this.showMenu}>
                <div className="brand-dropdown-text">{text}</div>
                <div className="brand-dropdown-icon"><Icon iconName="ChevronDown" /></div>
            </div>

            {type === PopUpType.Modal && this.modalElement()}
            {type === PopUpType.Panel && this.panelElement()}
        </>;
    }

    private modalElement = () => {
        const { showMenu } = this.state;

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={showMenu}
                onDismiss={this.hideMenu}
                isBlocking={true}
                dragOptions={this._dragOptions}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={Utils.createPanelStyle(10)}><b>Websites</b></div>
                            <div className={Utils.createPanelStyle(2) + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={this.hideMenu} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body" style={{ height: "80vh", overflowY: "auto" }}>
                    {this.body()}
                </div>
            </Modal>
        </>;
    }

    private panelElement = () => {
        const { showMenu } = this.state;

        return <>
            <Panel
                isOpen={showMenu}
                isBlocking={false}
                isLightDismiss={true}
                type={PanelType.custom}
                customWidth={DataUtilities.PANEL_WIDTH}
                onDismiss={this.hideMenu}
                closeButtonAriaLabel="Close"
                styles={{ content: { paddingLeft: "20px !important", paddingRight: "20px !important" } }}
            >
                {this.body()}
            </Panel>
        </>;
    }

    private body = () => {
        const { filteredOptions, listHash, recentItems } = this.state;

        return <>
            <div>
                <div className="pb-10"><b>Recent Selections: </b></div>
                {recentItems.map((item: any) => {
                    return <>
                        <DefaultButton text={item.text} className="filter-text" style={{ height: 25 }}
                            onClick={() => this.onRecentClick(item)} />
                    </>;
                })}
            </div>

            {this._searchBox}

            <div className="pv-10">
                {this.alphabetElement()}
            </div>

            <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
                <List items={filteredOptions} key={listHash} onRenderCell={this.onRenderRow} />
            </div>
        </>;
    }

    private onRenderRow = (item?: ShortWebsiteModel, index?: number) => {
        if (!item) return <></>;
        if (!index) index = 0;

        const background = index % 2 === 0 ? "aliceblue" : "white";

        return <>
            <div
                className="brand-item-row"
                style={{ backgroundColor: background }}
                onClick={() => this.onSelectedChange(item)}>
                {item.name} {item.earningPointRate > 0 && <>({item.earningPointRate}%)</>}
            </div>
        </>;
    }

    private alphabetElement = () => {
        const { options } = this.props;
        const obj: any = {};

        if (options) {
            for (let i = 0; i < options.length; i++) {
                const letter = options[i].name.substr(0, 1).toUpperCase();
                obj[letter] = letter;
            }
        }

        const list = [];

        for (let key in obj) list.push(key);

        return <div>
            {list.map((value: string) => <div className="brand-letter">
                <Link onClick={() => this.filterByLetter(value)}>{value}</Link>
            </div>)}

            <div className="brand-letter">
                <Link onClick={() => this.onTextChange("")}>ALL</Link>
            </div>
        </div>
    }

    private onTextChange = (newValue: string) => {
        const { options } = this.props;
        const req = new RegExp(newValue, 'i');

        const filteredOptions = options.filter((value: ShortWebsiteModel) => req.test(value.name));
        this.setState({ filteredOptions: filteredOptions, listHash: Utils.getRandomKey() });
    }

    private filterByLetter = (letter: string) => {
        const { options } = this.props;

        const filteredOptions = options.filter((value: ShortWebsiteModel) =>
            value.name.substr(0, 1).toUpperCase() === letter);

        this.setState({ filteredOptions: filteredOptions, listHash: Utils.getRandomKey() });
    }

    private onSelectedChange = (option: ShortWebsiteModel) => {
        const { onChange, type } = this.props;
        const { recentItems } = this.state;
        const value = option.id as number;
        this.setState({ value: value, text: option.name, showMenu: type === PopUpType.Panel });

        if (recentItems && !recentItems.find((item: any) => item.value === value)) {
            recentItems.unshift({ value: value, text: option.name });
            this.setState({ recentItems: recentItems });
        }

        onChange(value);
    }

    private onClear = () => {
        const { onChange } = this.props;
        this.setState({ value: undefined, text: undefined });
        onChange(undefined);
    }

    private onRecentClick = (item: any) => {
        const { onChange, type } = this.props;
        this.setState({ value: item.value, text: item.text, showMenu: type === PopUpType.Panel })
        onChange(item.value);
    }

    private getText = (value?: number) => {
        if (!value) return "";

        const { options } = this.props;
        const option = options && options.find((opt: ShortWebsiteModel) => opt.id === value);

        if (option) {
            var text = option.name;

            if (option.earningPointRate > 0) {
                text += " (" + option.earningPointRate + "%)";
            }

            return text;
        }

        return "";
    }

    private showMenu = () => {
        this.setState({ showMenu: true, filteredOptions: this.props.options });
    }

    private hideMenu = () => {
        this.setState({ showMenu: false });
    }
}