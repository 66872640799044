import * as React from 'react';
import "../navbar/navbar.scss";
import { Menu, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo3.png";
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { Authentication } from './Authentication';
import { BannerInfo } from '../../models/others/BannerInfo';
import { Utils } from './Utils';
import { HomeService } from '../../services/HomeService';
import { DataUtilities } from './DataUtilities';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
interface IMenuViewProps { }

interface IMenuViewState {
    banners: BannerInfo[];
    open: boolean;
    userRoles: UserRolesInfo;
}

export default class MenuView extends React.Component<IMenuViewProps, IMenuViewState> {
    constructor(props: IMenuViewProps) {
        super(props);

        this.state = {
            banners: [],
            open: false,
            userRoles: Authentication.getUserRoles()
        };
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        return <>
            {this.bannerElement()}
            {this.menuElement()}
        </>
    }

    private menuElement = () => {
        const { open, userRoles } = this.state;
        let menuStyle = open ? "menu open" : "menu";

        return <div className="navbar">
            <div className={menuStyle}>
                <ul style={{ display: "inline-flex", textAlign: "center" }}>
                    <Close className="close" onClick={this.toggleNavBar} />
                    <li onClick={this.toggleNavBar}>
                        <Link to="/privacy">Privacy</Link>
                    </li>
                    <li onClick={this.toggleNavBar}>
                        <Link to="/price-policy">Price Policy</Link>
                    </li>
                    <li onClick={this.toggleNavBar}>
                        <Link to="/terms">Terms</Link>
                    </li>
                    {userRoles.isSalesperson && <li onClick={this.toggleNavBar}>
                        <Link to="/orders">Orders</Link>
                    </li>}
                    {userRoles.isSalesperson && <li onClick={this.toggleNavBar}>
                        <Link to="/advertisements">Advertisements</Link>
                    </li>}
                    <li onClick={this.toggleNavBar}>
                        <Link to="/login">Login</Link>
                    </li>
                </ul>
            </div>
        </div>;
    }

    private bannerElement = () => {
        const { banners } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width8 = Utils.createPanelStyle(8);

        return <div className="primary-background-color ms-Grid">
            <div className={"ms-Grid-row"}>
                <div className={width2} style={{ textAlign: "left" }}>
                    <Link to="/"><img src={logo} alt="Bees Express" width={58} /></Link>
                </div>
                <div className={width8} style={{ paddingTop: 22 }}>
                    {!Utils.isArrayNullOrEmpty(banners) && <div>
                        {banners[0].text}
                    </div>}
                </div>
                <div className={width2}>
                    <div className="navbar pt-20">
                        <div className="hamburger">
                            <Menu onClick={this.toggleNavBar} />
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private getList = () => {
        const request = HomeService.getBanners();
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (result: IDataResponseResult) => {
        if (result.status === ResponseStatus.success) {
            this.setState({ banners: result.data });
        }
    }

    //private toggleNavBar = () => {
    //    this.setState({ open: window.innerWidth < 500 });
    //}

    private toggleNavBar = () => {
        if (window.innerWidth < 500) {
            this.setState({ open: !this.state.open });
        }
    }
}