import * as $ from 'jquery';
import { apiBaseUrl } from '../constants/url.constant';
import { CustomerFilterModel } from '../models/customers/CustomerModel';
import { DefaultService } from './DefaultService';

export class CustomerService extends DefaultService {
    private static controller: string = "Customer";

    public static RouteGetCustomerDropdown = (search: string, userOnly: boolean) =>
        `/Customer/getCustomerDropdown?search=${encodeURIComponent(search)}&userOnly=${userOnly}`;

    public static getCustomerDropdown(search: string, userOnly: boolean): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetCustomerDropdown(search, userOnly), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteGetEmployeeDropdown = () => `/${this.controller}/getEmployeeDropdown`;
    public static getEmployeeDropdown(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetEmployeeDropdown(), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteSearch = () => `/${this.controller}/search`;
    public static search(model: CustomerFilterModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteSearch(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteGetCountByTypes = () => `/${this.controller}/getCountByTypes`;
    public static getCountByTypes(model: CustomerFilterModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetCountByTypes(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }
}