import "./home.scss";
import banner from "../../assets/images/banner.jpg";
import box1 from "../../assets/images/box1.jpg";
import box2 from "../../assets/images/box2.jpg";
import { Utils } from "../../components/common/Utils";

const Home = () => {
    const width5 = Utils.createPanelStyle(5);
    const width7 = Utils.createPanelStyle(7);

    return (
        <div>
            <img src={banner} alt="Bees Express" style={{ width: "100%" }} />

            <div className="ms-Grid" style={{ paddingTop: 20 }}>
                <div className="ms-Grid-row">
                    <div className={width5 + " ph-10"}>
                        <img src={box1} alt="Bees Express" style={{ maxWidth: "100%" }} />
                    </div>
                    <div className={width7 + " home-text"}>
                        <div className="pl-0 title">Premium Services You Can Count On</div>
                        <div className="gray text">One-stop-shop and delivery with Bees Express. ZERO shopping fee and no hidden fees.</div>
                    </div>
                </div>

                <div className="ms-Grid-row pv-20">
                    <div className={width5 + " ph-10"}>
                        <img src={box2} alt="Bees Express" style={{ maxWidth: "100%" }} />
                    </div>
                    <div className={width7 + " home-text"}>
                        <div className="pl-0 title">Save on Packages Consolidation</div>
                        <div className="gray text">We shop on your behalf, combine your orders from different stores into one shipment and save on shipping.</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;