import "./home.scss";

const Privacy = () => {
    return (
        <div className="home-page-container ph-10 pt-20">
            <div className="home-page-body">
                <h1 className="pb-20">Privacy Policy</h1>
                <h3 className="pb-20">Effective as of January 2024</h3>
                <div className="policy">
                    <p>We at BEES EXPRESS. ("BEES," "we," "us," or "our") have created this privacy policy (this "Privacy Policy")
                        because we know that you care about how information you provide to us is used and shared. This Privacy Policy relates to
                        the information collection and use practices of BEES, including in connection with our Direct ordering Platform,
                        Discover marketplace Platform, and our other Services, platforms and websites.</p>

                    <p>By using our Services, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Service. By
                        accepting this Privacy Policy, accessing or using the Services, or otherwise manifesting your assent to this Privacy Policy,
                        you agree to be bound by the terms of this Privacy Policy and the accompanying Terms of Service. If you do not agree
                        to (or cannot comply with) all of the terms of this Privacy Policy, you may not access or use the Services.</p>

                    <p>If you accept or agree to this Privacy Policy on behalf of a company or other legal entity, you represent and warrant
                        that you have the authority to bind that company or other legal entity to the Privacy Policy and, in such event,
                        "you" and "your" will refer and apply to that company or other legal entity.</p>

                    <h3>THE INFORMATION WE COLLECT</h3>
                    <p>In the course of operating the Services, BEES collects or receives the following types of information,
                        which may include personal information</p>

                    <h4>1. CONTACT INFORMATION</h4>
                    <p>When you sign up to become a Registered User or enter a promotion or our referral program, you may be required to
                        provide us with personal information about yourself, such as your name, address, phone number, and e-mail address
                        (collectively, the "Contact Information"). We do not collect any Contact Information from you when you use our
                        Services unless you provide us with the Contact Information voluntarily (for example, by registering to use the
                        Services or joining our e-mail list).</p>

                    <h4>2. BILLING INFORMATION</h4>
                    <p>To submit orders through a Platform, you will be required to provide certain information in addition to the
                        Contact Information noted above. Such information may include bank name, bank account number, account holder name,
                        billing address, expiration data, and similar information (collectively, the "Billing Information").</p>

                    <h4>3. OTHER INFORMATION</h4>
                    <p>In addition to the Contact Information and the Billing Information, we may collect additional information regarding
                        you and your use of our Services, your interactions with us and our advertising, as well as information regarding
                        your computer or other devices used to access our Services (collectively, the "Other Information"). Such Other
                        Information may include:</p>

                    <ul style={{ listStyleType: "disc", paddingLeft: 30 }}>
                        <li><b>From You.</b> Additional information about yourself that you voluntarily provide to us, such as your orders,
                            gender, birthdate, interests, and more.</li>
                        <li><b>From Your Activity.</b> Information that we automatically collect when you use the Services, such as
                            (i) information about the device from which you access the Services (i.e., the type of device, the advertising
                            identifier ("IDFA" or "AdID"), the operating system and version (for example iOS, Android or Windows),
                            your carrier and network type (WiFi, 3G, 4G, 5G, LTE, etc.); (ii) your IP addresses (which may consist of a
                            static or dynamic IP address and will sometimes point to a specific identifiable computer or device);
                            (iii) browser type and language; (iv) referring and exit pages and URLs; (v) date and time; (vi) amount of
                            time spent on particular pages; (vii) what features of the Services you use or visit, including your use of
                            either Direct or Discover (or both); (viii) what you order; and (ix) similar information concerning your use of
                            the Services.</li>
                        <li><b>From Cookies.</b> Information that we collect using "cookie" technology. Cookies are small packets of data
                            that a website stores on your computer's hard drive so that your computer will "remember" information about
                            your visit. We use cookies to help us collect Other Information and to enhance your experience using the Services.
                            Specifically, we use cookies to authenticate users, personalize your experience when using the Services, analyze
                            which features of the Services you use most frequently, and measure and optimize advertising and promotional
                            effectiveness. To do this, we may use both session cookies, which expire once you close your web browser, and
                            persistent cookies, which stay on your computer until you delete them. If you do not want the Services to
                            place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device.
                            Please consult your Internet browser's documentation for information on how to do this. However, if you decide
                            not to accept cookies from us, the Services may not function properly. When the Services are made available to
                            you through Facebook (via our Plugin) and/or other third-party sites, please be aware that these other sites
                            maintain their own policies regarding cookies and the collection and use of information. You are responsible
                            for reviewing those policies.</li>
                        <li><b>From Other Sources.</b>. Information that we collect or receive from Facebook and/or other sites on which
                            you have used the Services in accordance with their terms of use and privacy policies and applicable law.</li>
                        <li><b>Third-Party Analytics.</b> We use third-party analytics services (such as Google Analytics) to evaluate
                            your use of the Services, compile reports on activity, collect demographic data, analyze performance metrics,
                            and collect and evaluate other information relating to the Services and mobile and Internet usage. These
                            third parties use cookies and other technologies to help analyze and provide us the data. By accessing and
                            using the Services, you consent to the processing of data about you by these analytics providers in the
                            manner and for the purposes set out in this Privacy Policy. The information used by such analytics services
                            is generally at the aggregate level.</li>
                        <li>For more information on Google Analytics, including how to opt out from certain data collection, please
                            visit https://www.google.com/analytics. Please be advised that if you opt out of any service, you may not
                            be able to use the full functionality of the Services.</li>
                    </ul>

                    <h3 className="pt-20">ACCESSING AND MODIFYING PERSONAL INFORMATION AND COMMUNICATION PREFERENCES</h3>
                    <p>If you have registered for the Services, you may access, review, and make changes to your Contact Information,
                        Billing Information, and certain Other Information as applicable. In addition, you may manage your receipt
                        of marketing and non-transactional communications by clicking on the "unsubscribe" link located on the bottom
                        of any BEES marketing email. Registered Users cannot opt out of receiving transactional e-mails
                        related to their account. We will use commercially reasonable efforts to process such requests in a timely manner.
                        You should be aware, however, that it is not always possible to completely remove or modify information in our
                        subscription databases. In such case, we will provide you the reason why the modification or removal was not possible.</p>

                    <h3>HOW WE USE AND SHARE INFORMATION</h3>
                    <p>We use the Contact Information and the Other Information to provide you access to and use of the Services,
                        solicit your feedback, inform you about our products and services and those of our third-party marketing partners,
                        to administer our rewards, referral, and promotional programs, to improve our service to you, and to evaluate job
                        applicants' candidacy for a position with BEES. We use the Billing Information to process your orders,
                        and we use the Geolocational Information to recommend nearby restaurants to you. Also, we may share Contact
                        Information, Billing Information, Geolocational Information, and/or Other Information as described below.</p>
                    <ul>
                        <li>To provide you our services, we need to share your name, phone number, and your physical address with any
                            third parties that are involved in, or provide services in connection with, such orders, such as delivery
                            services, licensees of our Platforms, and similar parties. Such parties will use such information in accordance
                            with the terms of their privacy policies.</li>
                        <li>In an ongoing effort to better understand our users and our Services, we might analyze Contact Information,
                            Billing Information, Geolocational Information and/or Other Information in aggregate form in order to operate,
                            maintain, manage, and improve the Services. This aggregate information does not identify you personally.
                            We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose
                            aggregated user statistics in order to describe our products and services to current and prospective business
                            partners and to other third parties for other lawful purposes.</li>
                        <li>We may employ other companies and individuals to perform functions on our behalf. Examples may include
                            providing marketing assistance, order fulfillment, billing, and customer service. These other companies will
                            have access to the Contact Information, the Billing Information, the Geolocational Information, and the Other
                            Information only as necessary to perform their functions and to the extent permitted by law.</li>
                        <li>As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale,
                            merger, reorganization, sale of assets, dissolution, investment, or similar corporate event, the Contact
                            Information, Billing Information, Geolocational Information, and Other Information may be part of the
                            transferred assets.</li>
                        <li>To the extent permitted by law, we may also disclose Contact Information, the Billing Information, the
                            Geolocational Information, and the Other Information when required by law, court order, or other government
                            or law enforcement authority or regulatory agency, or whenever we believe that disclosing such information
                            is necessary or advisable, for example, to protect the rights, property, or safety of BEES or others.</li>
                    </ul>

                    <p>We will take reasonable measures to require that any party receiving any of your personal information from us
                        undertakes to: (i) retain and use such information only for the purposes set out in this Privacy Policy;
                        (ii) not disclose your personal information except with your consent, as permitted by law, or as permitted by
                        this Privacy Policy; and (iii) generally protect the privacy of your personal information.</p>

                    <h3>THIRD-PARTY ADVERTISERS/REMARKETERS</h3>
                    <p>We share Other Information about your activity in connection with your use of the Services with third parties
                        for the purpose of tailoring, analyzing, managing, reporting, and optimizing advertising you see on the Platforms,
                        the Website, the Apps, the Plugin, and elsewhere. These third parties may use cookies, pixel tags (also called
                        web beacons or clear gifs), and/or other technologies to collect such Other Information for such purposes. Pixel
                        tags enable us, and these third-party advertisers, to recognize a browser's cookie when a browser visits the site
                        on which the pixel tag is located in order to learn which advertisement brings a user to a given site. By
                        accessing and using the Website, you consent to the processing of data about you by these advertisers/remarketing
                        providers in the manner and for the purposes set out in this Privacy Policy.</p>

                    <h3>HOW WE PROTECT YOUR INFORMATION</h3>
                    <p>We take commercially reasonable steps to protect the Contact Information, the Billing Information, the Geolocational
                        Information, and the Other Information from loss, misuse, and unauthorized access, disclosure, alteration, or
                        destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security
                        of our databases, nor can we guarantee that the information you supply will not be intercepted while being
                        transmitted to and from us over the Internet. In particular, e-mail sent to or from the Platform may not be
                        secure, and you should therefore take special care in deciding what information you send to us via e-mail
                        and/or messenger.</p>

                    <h3>IMPORTANT NOTICE TO NON--U.S. RESIDENTS</h3>
                    <p>The Services are operated in the United States. If you are located outside of the United States, please be aware
                        that any information you provide to us will be transferred to the United States where the privacy laws may not
                        be as protective as those in your country of origin. If you are located outside the United States and choose
                        to use the Services, you do so at your own risk.</p>

                    <h3>CHILDREN</h3>
                    <p>We do not knowingly collect personal information from children under the age of 13 through the Services. If you
                        are under 13, please do not give us any personal information. We encourage parents and legal guardians to
                        monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children
                        to never provide us personal information without their permission. If you have reason to believe that a child
                        under the age of 13 has provided personal information to us, please contact us, and we will endeavor to delete
                        that information from our databases.</p>

                    <h3>EXTERNAL WEBSITES, GOOGLE PAY, AND APPLE PAY</h3>
                    <p>The Services may contain links to third-party websites. BEES has no control over the privacy practices
                        or the content of any of our business partners, advertisers, sponsors, or other websites to which we provide links.
                        As such, we are not responsible for the content or the privacy policies of those third-party websites. You should
                        check the applicable third-party privacy policy and terms of use when visiting any other websites.</p>

                    <p>Please be advised that your use of Google Pay or Apple Pay is subject to the terms and conditions, including
                        the privacy policies, of Google and Apple, respectively. By using Google Pay, you hereby accept the Google Pay
                        API Terms of Service found here: <a href="https://payments.developers.google.com/terms/sellertos"
                            target="_blank">https://payments.developers.google.com/terms/sellertos</a>.</p>

                    <h3>CALIFORNIA PRIVACY RIGHTS</h3>
                    <p>If you are a resident of California you have additional rights under the California Consumer Privacy Act (the "CCPA").
                        For more information about your rights under the CCPA, please visit
                        our <a href="https://get.chownow.com/california-consumer-privacy-act/" target="_blank">CCPA Privacy Notice to California Residents.</a></p>

                    <h3>NEVADA PRIVACY RIGHTS</h3>
                    <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to third parties who intend
                        to license or sell that personal information. You can exercise this right by contacting us
                        at <a href={"mailto:sengbora2010@gmail.com"}>sengbora2010@gmail.com</a> with the subject line "Nevada Do Not Sell Request"
                        and providing us with your name and the email address associated with your account. Please note that we do not
                        currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.</p>

                    <h3>DO NOT TRACK</h3>
                    <p>We do not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser
                        "Do Not Track" settings and/or signals.</p>

                    <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                    <p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this
                        Privacy Policy from time to time, and will notify you of such changes (e.g., posting any changes on the Services,
                        e-mailing you, or prompting you via a popup). By accessing the Services after we notify you of such changes to
                        this Privacy Policy, you are deemed to have accepted such changes. Please refer back to this Privacy Policy on a
                        regular basis.</p>

                    <h3>HOW TO CONTACT US</h3>
                    <p>If you have questions about this Privacy Policy, please e-mail us at <a href={"mailto:sengbora2010@gmail.com"}>sengbora2010@gmail.com</a></p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;