import { ContextualMenu, getId, Icon, IContextualMenuProps, IDragOptions, Modal, TextField } from "@fluentui/react";
import React from "react";
import { IDataResponseResult, ResponseStatus } from "../../models/main/ResponseResult";
import { PhotoCollageInputModel } from "../../models/photoCollages/PhotoCollageModel";
import { AdvertisementService } from "../../services/AdvertisementService";
import { BasicDatePicker } from "../common/BasicDatePicker";
import { DataUtilities } from "../common/DataUtilities";
import { StyleUtils } from "../common/StyleUtils";
import { Utils } from "../common/Utils";
import ManagePictureView from "../pictures/ManagerPictureView";
import { AdvertisementPictureService } from "./AdvertisementView";

interface IAdvertisementDetailViewProps {
    id: number;
    pictureService: AdvertisementPictureService;
    onClose: (refresh: boolean) => void;
}

interface IAdvertisementDetailViewState {
    model: PhotoCollageInputModel;
}

export default class AdvertisementDetailView
    extends React.Component<IAdvertisementDetailViewProps, IAdvertisementDetailViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };
    private _commandMenuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: []
    };

    constructor(props: IAdvertisementDetailViewProps) {
        super(props);

        this.state = {
            model: new PhotoCollageInputModel()
        };
    }

    componentDidMount() {
        this.get();
    }

    render() {
        const { id, onClose, pictureService } = this.props;
        const { model } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={() => onClose(false)}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Advertisement Details</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={() => onClose(false)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    <div>
                        <TextField label="Brand" value={model.brand} />
                    </div>

                    <div className="pt-10">
                        <TextField label="Title" value={model.title} />
                    </div>

                    <div className="pt-10">
                        <TextField label="Description" value={model.description} />
                    </div>

                    <div className="pt-15">
                        Valid Date: {Utils.formatShortDate(new Date(model.start))} - {Utils.formatShortDate(new Date(model.end))}
                    </div>

                    <div className="pt-20 center">
                        <ManagePictureView ownerId={id} service={pictureService} onAction={this.onAction} />
                    </div>
                </div>
            </Modal>
        </>;
    }

    private onAction = (refresh: boolean) => {

    }

    private get = () => {
        const { id } = this.props;
        const request = AdvertisementService.get(id);
        const onResult = (result: IDataResponseResult) => {
            if (result.status === ResponseStatus.success) {
                this.setState({ model: result.data });
            }
        }

        DataUtilities.onRequest(request, onResult);
    }
}