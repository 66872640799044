export type emptyFunc = () => void;

export enum DateAvailability {
    expired = 0, active = 1, future = 2
}

export enum DropdownItemType {
    normal = 0, divider = 1, header = 2
}

export class CustomerDropdownItem {
    public id: number = 0;
    public name: string = "";
    public userId: string = "";
    public type: DropdownItemType = DropdownItemType.normal;
}

export class DropdownItem {
    public key: any = null;
    public text: string = "";
    public level: number = 0;
    public data: any = null;
    public type: DropdownItemType = DropdownItemType.normal;
}

export enum BooleanOption {
    no = 0, yes = 1
}

export class WarehouseKeyValueModel {
    public id: number = 0;
    public name: string = "";
    public color: string = "";
}

export class OptionModel {
    public key: any;
    public text: string = "";
    public current: boolean = false;
    public count: number = 0;
}

export class KeyValueModel {
    public key: string = "";
    public value: any;
}