import * as $ from 'jquery';
import { apiBaseUrl } from '../constants/url.constant';
import { DefaultService } from './DefaultService';

export class WebsiteService extends DefaultService {
    private static controller: string = "Website";

    public static RouteGetOpenList = () => `/${this.controller}/getOpenList`;
    public static getOpenList(): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetOpenList(), {
            type: 'get',
            contentType: 'application/json'
        });
    }
}