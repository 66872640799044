import { IDropdownOption, IComboBoxOption } from '@fluentui/react';

export type ComboBoxCallback = (option: IComboBoxOption) => void;
export enum FilterType {
    Textbox = 0, Dropdown = 1, Date = 2, Checkbox = 3, Radio = 4, Link = 5
}

export abstract class Filter {
    public name: string = "";
    public label: string = "";
    public value: any;
    public getText!: (value: any) => string;
}

export class TextboxFilter extends Filter { }
export class CheckboxFilter extends Filter { }
export class HiddenFilter extends Filter { }

export class ComboboxFilter extends Filter {
    public component?: (callback: ComboBoxCallback) => JSX.Element;
    public text: string | undefined;
}
export class DropdownFilter extends Filter {
    public options: IDropdownOption[] = [];
    public isMultiSelect: boolean = false;
}

class DateFilter extends Filter { }