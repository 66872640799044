import { PagedRow, PageFilterModel } from "../others/PageTypes";

export enum CustomerFilterType {
    all,
    openSaleOnly,
    invoiceAvailable,
    employeeOnly,
    userOnly
}

export class CustomerFilterModel extends PageFilterModel {
    filterType: CustomerFilterType = CustomerFilterType.all;
}

export class ShortCustomerModel implements PagedRow {
    id: number = 0;
    userId: string = "";
    displayName: string = "";
    countryCode: string = "";
    phoneNo: string = "";
    email: string = "";
    isEmployee: boolean = false;
    isUser: boolean = false;
    isOpenSales: boolean = false;
    start: Date = new Date(0);
    saleDate?: Date;
    pictureId?: number;
    phones?: string;
    tokenCount: number = 0;
    lastConnectedDate?: Date;
    imageUrl: string = "";
    totalPoint: number = 0;
}