import { Icon } from '@fluentui/react';
import * as React from 'react';
import { FileCategory, FileType } from '../../models/products/PictureModel';
import { ProductUtilities } from '../products/ProductUtilities';
import { Utils } from "../common/Utils";

interface IPictureUploadProps {
    isEdit: boolean;
    imageUrl?: string;
    fileType: FileType;
    fileCategory: FileCategory;
    onAction: (file?: File) => void;
}

interface IPictureUploadState {
    file?: File;
    displayPicture: any;
}

export default class PictureUpload extends React.Component<IPictureUploadProps, IPictureUploadState> {
    constructor(props: IPictureUploadProps) {
        super(props);
        this.state = {
            file: undefined,
            displayPicture: this.props.imageUrl
        };
    }

    public render() {
        const { isEdit, fileType, fileCategory } = this.props;
        const { displayPicture } = this.state;
        const display = isEdit ? "none" : "normal";

        return <div className="pv-10">
            <div style={{ width: "100% !important" }}>
                {!Utils.isNullOrEmpty(displayPicture) && <>
                    <Icon iconName="StatusCircleErrorX"
                        onClick={this.clearFileInput}
                        className="icon delete-cart" />
                </>}

                {ProductUtilities.createImageElement2(fileType, displayPicture, fileCategory, null, () => { })}
            </div>

            <div className="pt-10 pb-5 center">
                <input id="uploadFile" name="uploadFile" type="file"
                    onChange={(e) => this.onLoadFile(e.target)} style={{ display: display }} />
            </div>
        </div>;
    }

    private onLoadFile = (event: HTMLInputElement) => {
        if (event.files && event.files.length > 0) {
            let file = event.files[0];
            this.setState({ file: file });
            this.props.onAction(file);
            this.loadDisplayPicture(file);
        }
    }

    private loadDisplayPicture = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ displayPicture: [reader.result] });
        };
    }

    private clearFileInput() {
        var oldInput = document.getElementById("uploadFile");

        var newInput = document.createElement("input");

        newInput.type = "file";
        newInput.id = "uploadFile";
        newInput.name = "uploadFile";
        newInput.onchange = (e: any) => this.onLoadFile(e.target);

        if (oldInput) {
            newInput.className = oldInput.className;
            newInput.style.cssText = oldInput.style.cssText;

            if (oldInput.parentNode) {
                oldInput.parentNode.replaceChild(newInput, oldInput);
            }
        }

        this.setState({ file: undefined, displayPicture: undefined });
        this.props.onAction(undefined);
    }
}