import { Link } from "@fluentui/react";
import React from "react";
import { baseUrl } from "../../constants/url.constant";
import { FileCategory, FileType, EditPictureModel, PictureModel } from "../../models/products/PictureModel";
import { Utils } from "../common/Utils";
import { ProductUtilities } from "../products/ProductUtilities";
import AddPictureListView from "./AddPictureListView";
import EditPictureListView from "./EditPictureListView";

export type onGetPicturesCallback = (pictures: PictureModel[]) => void;

export interface IPictureService {
    delete: (ownerId: any, id: number) => JQueryPromise<any>;
    create: (ownerId: any, model: FormData) => JQueryPromise<any>;
    edit: (ownerId: any, model: EditPictureModel) => JQueryPromise<any>;
    editList: (ownerId: any, pictures: EditPictureModel[]) => JQueryPromise<any>;
    getList(ownerId: any, callback: onGetPicturesCallback): void;
}

interface IManagePictureViewProps {
    ownerId: any;
    service: IPictureService;
    onAction: (refresh: boolean) => void;
    isReadonly?: boolean;
}

interface IManagePictureViewState {
    pictures: PictureModel[];
    showEntry: boolean;
    selectedPicture?: PictureModel;
    listHash: number;
    showAdd: boolean;
    showEdit: boolean;
}

export default class ManagePictureView extends React.Component<IManagePictureViewProps, IManagePictureViewState> {
    constructor(props: IManagePictureViewProps) {
        super(props);

        this.state = {
            pictures: [],
            showEntry: false,
            listHash: -1,
            showAdd: false,
            showEdit: false
        }
    }

    componentDidMount() {
        this.getList(this.props.ownerId);
    }

    componentWillReceiveProps(newProps: IManagePictureViewProps) {
        if (this.props.ownerId !== newProps.ownerId) this.getList(newProps.ownerId);
    }

    render() {
        const { isReadonly } = this.props;
        const { selectedPicture, pictures, showAdd, showEdit } = this.state;

        const url = selectedPicture ? selectedPicture.imageUrl : "";
        const type = selectedPicture ? selectedPicture.type : FileType.image;

        return <>
            <div className="pb-10">
                <span style={{ fontWeight: "bold", fontSize: "larger" }}>Pictures: </span>
                {!isReadonly && <Link onClick={() => this.onAdd()}>Add New</Link>}
            </div>

            {ProductUtilities.getLargePicture(type, url, this.onEdit)}

            <div className="pv-20" style={{ display: "inline-flex" }}>
                {pictures.map(this.onRenderMiniPicture)}
            </div>

            {showAdd && <AddPictureListView create={this.create} onClose={this.onAddClose} />}
            {showEdit && <EditPictureListView edit={this.edit} pictures={pictures} onClose={this.onEditClose} />}
        </>;
    }

    private onRenderMiniPicture = (picture: PictureModel) => {
        var text = (picture.isPrimary ? "Primary, " : "") + "Category: " + FileCategory[picture.category];
        text += ", Size: " + (picture.length / 1024).toFixed(2) + " KB, Name: " + picture.fileName;

        return <div key={picture.id}>
            {ProductUtilities.getMiniPicture(picture.type, picture.imageUrl, text, () => this.onSelectMiniPicture(picture))}
        </div>;
    }

    private onSelectMiniPicture = (picture: PictureModel) => {
        this.setState({ selectedPicture: picture });
    }

    private onAdd = () => {
        this.setState({ showAdd: true });
    }

    private onAddClose = (refresh: boolean) => {
        this.setState({ showAdd: false });

        if (refresh) this.getList(this.props.ownerId);
    }

    private onEdit = () => {
        this.setState({ showEdit: true });
    }

    private onEditClose = (refresh: boolean) => {
        this.setState({ showEdit: false });

        if (refresh) this.getList(this.props.ownerId);
    }

    private create = (model: FormData) => {
        const { service, ownerId } = this.props;
        return service.create(ownerId, model);
    }

    private edit = (pictures: EditPictureModel[]) => {
        const { service, ownerId } = this.props;
        return service.editList(ownerId, pictures);
    }

    private getList = (ownerId: number) => {
        this.props.service.getList(ownerId, this.onGetPicturesCallback);
    }

    private onGetPicturesCallback = (pictures: PictureModel[]) => {
        let selectedPicture: PictureModel | undefined = undefined;

        if (pictures.length > 0) {
            selectedPicture = pictures[0];
        }

        this.setState({
            pictures: pictures,
            selectedPicture: selectedPicture,
            listHash: Utils.getRandomKey()
        });
    }
}