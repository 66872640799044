import * as React from 'react';
import { DataUtilities } from '../common/DataUtilities';
import { Utils } from '../common/Utils';
import { Authentication } from '../common/Authentication';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { OrderModel, OrderStatus } from '../../models/orders/OrderModel';
import WarehouseView from '../products/WarehouseView';
import { WarehouseKeyValueModel } from '../../models/others/OtherTypes';
import { OrderService } from '../../services/OrderService';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { OrderUtilities } from './OrderUtilities';

interface IChangeOrderWarehouseViewProps {
    item: OrderModel;
    onCompleted?: (refresh: boolean) => void;
}

interface IChangeOrderWarehouseViewState {
    isSubmitting: boolean;
    showWarehouseList: boolean;
    userRoles: UserRolesInfo;
}

export default class ChangeOrderWarehouseView
    extends React.Component<IChangeOrderWarehouseViewProps, IChangeOrderWarehouseViewState> {
    constructor(props: IChangeOrderWarehouseViewProps) {
        super(props);

        this.state = {
            isSubmitting: false,
            showWarehouseList: false,
            userRoles: Authentication.getUserRoles()
        }
    }

    render() {
        const { item } = this.props;
        const { showWarehouseList } = this.state;

        return <>
            <div className="pb-10" style={{ fontWeight: "bold" }}>Warehouse</div>
            <div className="fl-grid">
                <div className="fl-span7">{item.warehouse.name}</div>
                <div className="fl-span5 right">
                    {OrderUtilities.isPendingStatus(item.status) && <>
                        <a href="#" onClick={this.showWarehouseList}>Change</a>
                        <span className="ph-5">|</span>
                        <a href="#" onClick={this.unassignWarehouse}>Unassign</a>
                    </>}
                </div>
            </div>

            {showWarehouseList && <>
                <WarehouseView onSelect={this.onChangeWarehouse} onClose={this.closeWarehouseList} />
            </>}
        </>;
    }

    //private isAuthorizedWarehouse = () => {
    //    const { userRoles } = this.state;
    //    const warehouseId = this.props.item.warehouse.id;
    //    const authorizedWarehouses = userRoles.warehouses;

    //    const warehouse = authorizedWarehouses
    //        .find((warehouse: WarehouseKeyValueModel) => warehouse.id === warehouseId);

    //    return !Utils.isNull(warehouse);
    //}

    private showWarehouseList = () => {
        this.setState({ showWarehouseList: true });
    }

    private closeWarehouseList = () => {
        this.setState({ showWarehouseList: false });
    }

    private onChangeWarehouse = (newWarehouse: WarehouseKeyValueModel) => {
        if (window.confirm("Are you sure to change warehouse to " + newWarehouse.name + "?")) {
            const { item, onCompleted } = this.props;
            this.closeWarehouseList();
            const request = OrderService.changeWarehouse(item.id, newWarehouse.id);
            const onResult = (result: IDataResponseResult) => {
                if (result.status === ResponseStatus.success && onCompleted) onCompleted(true);
            }

            DataUtilities.onRequest(request, onResult);
        }
    }

    private unassignWarehouse = () => {
        if (window.confirm("Are you sure to unassign warehouse?")) {
            const { item, onCompleted } = this.props;
            this.closeWarehouseList();
            const request = OrderService.unassignWarehouse(item.id);
            const onResult = (result: IDataResponseResult) => {
                if (result.status === ResponseStatus.success && onCompleted) onCompleted(true);
            }

            DataUtilities.onRequest(request, onResult);
        }
    }
}