import * as React from 'react';
import { Dropdown, IDropdownOption, TextField, Icon, Link } from '@fluentui/react';
import { Page } from './Page';
import { DataUtilities } from '../common/DataUtilities';
import { Utils } from '../common/Utils';

interface IPagerProps {
    onChange: (page: Page) => void;
    hideGoTop?: boolean;
    totalCount: number;
    pageSize?: number;
}

interface IPagerState {
    page: Page;
}

class Pager extends React.Component<IPagerProps, IPagerState> {
    private _sizeOptions = [
        { key: 2, text: "2" },
        { key: 5, text: "5" },
        { key: 10, text: "10" },
        { key: 25, text: "25" },
        { key: 50, text: "50" },
        { key: 100, text: "100" }
    ];

    constructor(props: IPagerProps) {
        super(props);
        console.log("Pager constructor");
        let page = new Page();
        page.totalItemCount = props.totalCount;
        page.pageNumber = 1;
        page.pageSize = props.pageSize ? props.pageSize : DataUtilities.DEFAULT_GRID_PAGE_SIZE;
        page.pageCount = this.calculatePageCount(props.totalCount, page.pageSize);

        this.state = { page: page };
    }

    //*******************************************************
    //******************* Methods ***************************
    //*******************************************************

    // Set PageNumber = 1
    private onMoveFirst = () => {
        let page = this.state.page;

        if (page.pageNumber <= 1) return;

        page.pageNumber = 1;
        this.setState({ page: page });

        if (this.props.onChange) this.props.onChange(page);
    }

    // Set PageNumber-- and make sure no less than 1
    private onMovePrevious = () => {
        let page = this.state.page;

        if (page.pageNumber <= 1) return;

        page.pageNumber--;

        if (page.pageNumber < 1) page.pageNumber = 1;

        this.setState({ page: page });

        if (this.props.onChange) this.props.onChange(page);
    }

    // Set PageNumber++ and make sure no greater than PageCount
    private onMoveNext = () => {
        let page = this.state.page;

        if (page.pageNumber >= page.pageCount) return;

        page.pageNumber++;

        if (page.pageNumber > page.pageCount) {
            page.pageNumber = page.pageCount;
        }

        this.setState({ page: page });

        if (this.props.onChange) this.props.onChange(page);
    }

    // Set PageNumber = PageCount
    private onMoveLast = () => {
        let page = this.state.page;

        if (page.pageNumber >= page.pageCount) return;

        page.pageNumber = page.pageCount;
        this.setState({ page: page });

        if (this.props.onChange) this.props.onChange(page);
    }

    // Make sure it is numeric and between 1 and PageCount
    private onPageNumberChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, number: string | undefined) => {
        let page = this.state.page;
        const oldPageNumber = page.pageNumber;
        let num = parseInt(number ?? "0");

        if (isNaN(num)) {
            Utils.sendNotification("Please input number only.");
            return;
        }

        if (num < 1 || num > page.pageCount) {
            Utils.sendNotification("Please input number between 1 and " + page.pageCount);
            num = page.pageNumber;
        }

        page.pageNumber = num;
        this.setState({ page: page });

        if (oldPageNumber !== num && this.props.onChange) this.props.onChange(page);
    }

    // When PageSize change, recalculate PageCount and reset PageNumber = 1
    private onPageSizeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        let page = this.state.page;
        page.pageNumber = 1;
        page.pageSize = item ? item.key as number : 50;
        page.pageCount = this.calculatePageCount(page.totalItemCount, page.pageSize);
        this.setState({ page: page });

        if (this.props.onChange) this.props.onChange(page);
    }

    // Calculate page count
    private calculatePageCount = (num: number, size: number): number => {
        return Math.ceil(num / size);
    }

    //*******************************************************
    //******************** UI *******************************
    //*******************************************************

    public render() {
        const { hideGoTop } = this.props;
        const page = this.state.page;

        return <div style={{ textAlign: "center" }}>
            <div className="pt-15 pb-10" style={{ display: "inline-block" }}>
                <Icon iconName="DoubleChevronLeftMed" onClick={this.onMoveFirst}
                    className="icon pager" />

                <Icon iconName="ChevronLeftMed" onClick={this.onMovePrevious}
                    className="icon pager" />

                <TextField label="" value={page.pageNumber.toString()}
                    onChange={this.onPageNumberChange} className="pager-textbox"
                    styles={{ field: { textAlign: "center" } }} />

                {!page.hidePageCount && <span className="pager-label">({page.pageCount} pages)</span>}

                <Icon iconName="ChevronRightMed" onClick={this.onMoveNext}
                    className="icon pager" />

                <Icon iconName="DoubleChevronLeftMedMirrored"
                    onClick={this.onMoveLast} className="icon pager" />

                <Dropdown
                    selectedKey={page.pageSize}
                    onChange={this.onPageSizeChange}
                    options={this._sizeOptions}
                    className="pager-dropdown pl-5"
                    styles={{ root: { display: "inline-block" } }} />
            </div>

            {!hideGoTop && <div className="go-top">
                <Link href="#top-page"><Icon iconName="Movers" /></Link>
            </div>}
        </div>;
    }
}

export { Pager }