import { DefaultButton, ProgressIndicator, TextField } from '@fluentui/react';
import * as React from 'react';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { EditOrderModel, OrderModel } from '../../models/orders/OrderModel';
import { OrderService } from '../../services/OrderService';
import { DataUtilities } from '../common/DataUtilities';
import { Utils } from '../common/Utils';

interface IEditOrderPriceViewProps {
    item: OrderModel;
    onCompleted: () => void;
}

interface IEditOrderPriceViewState {
    model: EditOrderModel;
    isSubmitting: boolean;
    showPicture: boolean;
}

export default class EditOrderPriceView extends React.Component<IEditOrderPriceViewProps, IEditOrderPriceViewState> {
    constructor(props: IEditOrderPriceViewProps) {
        super(props);

        this.state = {
            model: this.initialEditModel(props.item),
            isSubmitting: false,
            showPicture: false
        }
    }

    private initialEditModel(item: OrderModel) {
        const model = new EditOrderModel();
        model.id = item.id;
        model.price = item.price;
        model.qty = item.qty;
        model.rate = item.rate;
        model.domesticShipping = item.domesticShipping;
        model.chargeWeight = item.chargeWeight;
        model.shippingRate = item.shippingRate;
        model.note = item.note;
        model.trackingNo = item.trackingNo;

        return model;
    }

    render() {
        const { isSubmitting } = this.state;

        return <div className="ms-Grid box mt-20 p-15">
            {this.inputForm()}

            {!isSubmitting && <div className="pt-15 right">
                <DefaultButton onClick={this.submit}>Save Change</DefaultButton>
            </div>}

            {isSubmitting && <div className="pt-15">
                <ProgressIndicator label="Submitting" description="" />
            </div>}
        </div>;
    }

    private inputForm = () => {
        const { model } = this.state;
        const width3 = Utils.createPanelStyle(3);
        const amount = parseFloat(model.price.toString()) * parseFloat(model.qty.toString()) *
            (100 + parseFloat(model.rate.toString())) / 100;
        const total = amount + parseFloat(model.domesticShipping.toString()) +
            (parseFloat(model.shippingRate.toString()) * parseFloat(model.chargeWeight.toString()));
        
        return <>
            <div className="ms-Grid-row">
                <div className={width3}>
                    <TextField label="Price" value={model.price.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("price", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Qty" value={model.qty.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("qty", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Rate" value={model.rate.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("rate", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Amount" value={amount.toFixed(2)} readOnly={true} />
                </div>
            </div>

            <div className="ms-Grid-row pt-10">
                <div className={width3}>
                    <TextField label="Ship in US" value={model.domesticShipping.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("domesticShipping", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Ship Rate" value={model.shippingRate.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("shippingRate", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Weight" value={model.chargeWeight.toString()}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
                            this.onValueChange("chargeWeight", newValue)} />
                </div>
                <div className={width3}>
                    <TextField label="Total" value={total.toFixed(2)} readOnly={true} />
                </div>
            </div>
        </>
    }

    private submit = () => {
        const { model } = this.state;
        let request = OrderService.editPrice(model);

        this.setState({ isSubmitting: true });
        DataUtilities.onRequest(request, this.onResult);
    }

    private onResult = (result: IDataResponseResult) => {
        const { onCompleted } = this.props;

        if (result.status === ResponseStatus.success && onCompleted) {
            onCompleted();
        }

        this.setState({ isSubmitting: false });
    }

    private onValueChange = (name: string, value: any) => {
        let model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }
}