import React from "react";
import "./site.css";
import "./fabric.css"
import { Routes, Route } from "react-router-dom";
import Login from "./pages/accounts/Login.page";
import AddProduct from "./pages/products/AddProduct.page";
import DeleteProduct from "./pages/products/DeleteProduct.page";
import EditProduct from "./pages/products/EditProduct.page";
import Home from "./pages/home/Home.page";
import Order from "./pages/orders/Order.page";
import Products from "./pages/products/Products.page";
import { Authentication } from "./components/common/Authentication";
import { createTheme, loadTheme } from "@fluentui/react";
import Privacy from "./pages/home/Privacy.page";
import PricePolicy from "./pages/home/PricePolicy.page";
import Term from "./pages/home/Terms.page";
import MenuView from "./components/common/MenuView";
import FooterView from "./components/common/FooterView";
import Advertisement from "./pages/advertisements/Advertisement.page";

const myTheme = createTheme({
    palette: {
        themePrimary: '#fde354',
        themeLighterAlt: '#0a0801',
        themeLighter: '#282002',
        themeLight: '#4c3c04',
        themeTertiary: '#977808',
        themeSecondary: '#deb00b',
        themeDarkAlt: '#fdce25',
        themeDark: '#fdd546',
        themeDarker: '#fee077',
        neutralLighterAlt: '#ffffff',
        neutralLighter: '#ffffff',
        neutralLight: '#ffffff',
        neutralQuaternaryAlt: '#ffffff',
        neutralQuaternary: '#ffffff',
        neutralTertiaryAlt: '#ffffff',
        neutralTertiary: '#bab8b7',
        neutralSecondary: '#a3a2a0',
        neutralSecondaryAlt: '#a3a2a0',
        neutralPrimaryAlt: '#8d8b8a',
        neutralPrimary: '#323130',
        neutralDark:'#605e5d',
        black: '#494847',
        white: 'white'
    }
});

loadTheme(myTheme);

const App: React.FC = () => {
    Authentication.setupAjaxAuthorization();

    return (
        <div className="home-page-container">
            <div className="home-page-body">
                <MenuView />

                <div style={{ textAlign: "left" }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/price-policy" element={<PricePolicy />} />
                        <Route path="/terms" element={<Term />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/orders" element={<Order />} />
                        <Route path="/advertisements" element={<Advertisement />} />
                        <Route path="/products">
                            <Route index element={<Products />} />
                            <Route path="add" element={<AddProduct />} />
                            <Route path="edit/:id" element={<EditProduct />} />
                            <Route path="delete/:id" element={<DeleteProduct />} />
                        </Route>
                    </Routes>
                </div>

                <FooterView />
            </div>
        </div>
    );
};

export default App;