import * as $ from 'jquery';
import { DefaultService } from "./DefaultService";
import { apiBaseUrl } from '../constants/url.constant';
import { EditPictureModel } from "../models/products/PictureModel";
import {
    PhotoCollageFilterModel, PhotoCollageInputModel, PhotoCollageTemplateModel
} from "../models/photoCollages/PhotoCollageModel";

export class AdvertisementService extends DefaultService {
    private static controller: string = "Advertisement";

    public static RouteGet = (id: number) => `/${this.controller}/get/${id}`;
    public static get(id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGet(id), {
            type: 'get',
            contentType: 'application/json'
        });
    }

    public static RouteSearch = () => `/${this.controller}/search`;
    public static search(model: PhotoCollageFilterModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteSearch(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteCreate = () => `/${this.controller}/create`;
    public static create(model: PhotoCollageInputModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteCreate(), {
            type: 'post',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteEdit = (id: number) => `/${this.controller}/edit/${id}`;
    public static edit(id: number, model: PhotoCollageInputModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteEdit(id), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteChangeTemplate = (id: number) => `/${this.controller}/changeTemplate/${id}`;
    public static changeTemplate(id: number, model: PhotoCollageTemplateModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteChangeTemplate(id), {
            type: 'put',
            data: JSON.stringify(model),
            contentType: 'application/json'
        });
    }

    public static RouteActivate = (id: number, active: boolean) => `/${this.controller}/activate/${id}?active=${active}`;
    public static activate(id: number, active: boolean): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteActivate(id, active), {
            type: 'put',
            data: undefined,
            contentType: 'application/json'
        });
    }

    public static RouteDelete = (id: number) => `/${this.controller}/delete/${id}`;
    public static delete(id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteDelete(id), {
            type: 'delete',
            data: undefined,
            contentType: 'application/json'
        });
    }

    public static RouteCreatePictures = (ownerId: number) => apiBaseUrl + `/${this.controller}/createPictures/${ownerId}`;

    public static RouteEditPicture = (ownerId: number) => `/${this.controller}/editPicture/${ownerId}`;
    public static editPicture(ownerId: number, picture: EditPictureModel): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteEditPicture(ownerId), {
            type: 'put',
            data: JSON.stringify(picture),
            contentType: 'application/json'
        });
    }

    public static RouteEditPictures = (ownerId: number) => `/${this.controller}/editPictures/${ownerId}`;
    public static editPictures(ownerId: number, pictures: EditPictureModel[]): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteEditPictures(ownerId), {
            type: 'put',
            data: JSON.stringify(pictures),
            contentType: 'application/json'
        });
    }

    public static RouteGetPictures = (ownerId: number) => `/${this.controller}/getPictures/${ownerId}`;
    public static getPictures(ownerId: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteGetPictures(ownerId), {
            type: 'get',
            data: undefined,
            contentType: 'application/json'
        });
    }

    public static RouteDeletePicture = (ownerId: number, id: number) => `/${this.controller}/deletePicture/${ownerId}?id=${id}`;
    public static deletePicture(ownerId: number, id: number): JQueryPromise<any> {
        return $.ajax(apiBaseUrl + this.RouteDeletePicture(ownerId, id), {
            type: 'delete',
            data: undefined,
            contentType: 'application/json'
        });
    }
}