import { PictureModel } from "./PictureModel";
import { WebsiteModel } from "./WebsiteModel";

export class EditProductModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    link: string = "";
    weight: number = 0.0;
    dimension: string = "";
    websiteId: number = 0;
    note: string = "";
}

export class ProductModel extends EditProductModel {
    website?: WebsiteModel = undefined;
    picture?: PictureModel = undefined;
}