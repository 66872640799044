import { PhotoSize } from "../../models/photoCollages/PhotoCollageModel";
import { PhotoCollagePostModel } from "../../models/products/PictureModel";
import PhotoCollageBaseView, { IPhotoCollageBaseViewProps } from "./PhotoCollageBaseView";

export default class TwoPhotoView extends PhotoCollageBaseView {
    constructor(props: IPhotoCollageBaseViewProps) {
        super(props);
    }

    public render() {
        const { photos } = this.props;

        return <div style={{ display: "inline-block" }}>
            <div style={{
                float: this._style.outerFloat,
                paddingRight: this._style.paddingBottom,
                paddingBottom: this._style.paddingRight
            }}>
                {this.getPhoto(photos[0])}
            </div>
            <div style={{ float: this._style.innerFloat }}>
                {this.getPhoto(photos[1])}
            </div>
        </div>;
    }

    private getPhoto = (photo: PhotoCollagePostModel): JSX.Element => {
        const { photoManager } = this.props;
        const isTall = this._layoutManager.isTall();

        return <>
            {photoManager.getRectangle(
                photo,
                PhotoSize.half,
                isTall ? PhotoSize.extraFull : PhotoSize.full,
                this._layoutManager.isTall()
            )}
        </>;
    }
}