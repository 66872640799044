import { ActionButton, DocumentCard, DocumentCardImage, ImageFit } from '@fluentui/react';
import * as React from 'react';
import { Utils } from '../common/Utils';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import { OrderModel, OrderStatus } from '../../models/orders/OrderModel';
import { OrderService } from '../../services/OrderService';
import { DataUtilities } from '../common/DataUtilities';
import { ProductUtilities } from '../products/ProductUtilities';
import OrderTrackingView from './OrderTrackingView';
import { OrderUtilities } from './OrderUtilities';

interface IOrderItemViewProps {
    order: OrderModel;
    onDetail: (id: number) => void;
    onCompleted: () => void;
}

interface IOrderItemViewState { }

export default class OrderItemView extends React.Component<IOrderItemViewProps, IOrderItemViewState> {
    private _width3 = Utils.createPanelStyle(3);
    private _width4 = Utils.createPanelStyle(4);
    private _width8 = Utils.createPanelStyle(8);

    constructor(props: IOrderItemViewProps) {
        super(props);

        this.state = {
            showDetail: false
        };
    }

    render() {
        const { order } = this.props;

        let url = "";

        if (order.product) url = ProductUtilities.getProductImageUrl(order.product.picture);

        const amount = order.price * order.qty * (100 + order.rate) / 100;
        const total = amount + order.domesticShipping + order.chargeWeight * order.shippingRate;

        let shippingText = "Shipping ($" + Utils.formatNumber(order.shippingRate);
        shippingText += " x " + Utils.formatNumber(order.chargeWeight) + "lbs)";

        const width6 = Utils.createPanelStyle(6);

        return <DocumentCard className="picture-wrapper order-product-picture-card"
            styles={{ root: { maxWidth: "100%", paddingLeft: 10, paddingRight: 10 } }}>
            <div className="ms-Grid">
                <div className="ms-Grid-row p-10">
                    <DocumentCardImage height={200} imageFit={ImageFit.centerCover}
                        className="product-picture image-radius" imageSrc={url} />
                </div>

                <div className="ms-Grid-row ph-10">
                    <div className={width6 + " font-bold"}>{order.customer}</div>
                    <div className={width6 + " right font-smaller"}>
                        {order.warehouse.name} - {Utils.splitCapitalText(OrderStatus[order.status])}
                    </div>
                </div>

                <div className="ms-Grid-row ph-20 pt-5 gray font-smaller">{Utils.formatLongDate(order.date)}</div>

                {order.product && <div className="ph-10 pv-10">
                    {!Utils.isNullOrEmpty(order.product.name) && <div>{order.product.name}</div>}

                    <div className="gray">
                        {!Utils.isNullOrEmpty(order.product.description) && <div>{order.product.description}</div>}
                        {order.product.weight > 0 && <div className="font-smaller">Weight: {Utils.formatNumber(order.product.weight)} lbs</div>}
                        {!Utils.isNullOrEmpty(order.product.dimension) && <div className="font-smaller">{order.product.dimension}</div>}
                    </div>
                </div>}

                {this.headerRow()}
                {this.priceRow(amount)}

                {this.summaryRow("Shipping in US", order.domesticShipping)}
                {this.summaryRow(shippingText, order.chargeWeight * order.shippingRate)}
                <div className="font-bold">{this.summaryRow("Total", total)}</div>

                {OrderUtilities.isOrderStatus(order.status) && <div className="ph-10">
                    <OrderTrackingView trackings={order.trackings} />
                </div>}

                {this.actionRow()}
            </div>
        </DocumentCard>;
    }

    private headerRow = () => {
        return <div className="ms-Grid font-bolder" style={{ backgroundColor: Utils.altRowColor }}>
            <div className="ms-Grid-row p-10">
                <div className={this._width3}>Price</div>
                <div className={this._width3 + " right"}>Qty</div>
                <div className={this._width3 + " right"}>Rate</div>
                <div className={this._width3 + " right"}>Amount</div>
            </div>
        </div>;
    }

    private priceRow = (amount: number) => {
        const { order } = this.props;

        return <div className={"ms-Grid"}>
            <div className="ms-Grid-row p-10">
                <div className={this._width3}>$ {Utils.formatNumber(order.price)}</div>
                <div className={this._width3 + " right"}>{order.qty}</div>
                <div className={this._width3 + " right"}>{order.rate} %</div>
                <div className={this._width3 + " right"}>$ {Utils.formatNumber(amount)}</div>
            </div>
        </div>;
    }

    private summaryRow = (label: string, value: number) => {
        return <div className="ms-Grid" style={{ backgroundColor: Utils.altRowColor }}>
            <div className="ms-Grid-row p-10">
                <div className={this._width8 + " right"}>{label}</div>
                <div className={this._width4 + " right"}>$ {Utils.formatNumber(value)}</div>
            </div>
        </div>;
    }

    private actionRow = () => {
        const { order, onDetail } = this.props;

        return <div className="disable-action-button ms-Grid ph-5 primary-background-color">
            <div className="ms-Grid-row">
                <div className={this._width4}>
                    <ActionButton
                        iconProps={{ iconName: "Product" }}
                        styles={{ icon: { color: "black" } }}
                        onClick={this.onProductDetail}>Prodct</ActionButton>
                </div>
                <div className={this._width4 + " center"}>
                    {order.status === OrderStatus.quote && <>
                        <ActionButton
                            iconProps={{ iconName: "ShoppingCart" }}
                            styles={{ icon: { color: "black" } }}
                            onClick={this.placeOrder}>Place Order</ActionButton>
                    </>}
                </div>
                <div className={this._width4 + " right"}>
                    <ActionButton
                        iconProps={{ iconName: "CheckList" }}
                        styles={{ icon: { color: "black" } }}
                        onClick={() => onDetail(order.id)}>Detail</ActionButton>
                </div>
            </div>
        </div>;
    }

    private onProductDetail = () => {
        const { order } = this.props;

        if (order.product && order.product.link) {
            window.open(order.product.link, "_blank");
        } else {
            alert("Product link not available.");
        }
    }

    private placeOrder = () => {
        if (window.confirm("Would you like to place order?")) {
            const { order, onCompleted } = this.props;

            const onResult = (result: IDataResponseResult) => {
                if (result.status === ResponseStatus.success) {
                    onCompleted();
                }
            }

            const request = OrderService.placeOrder(order.id);
            DataUtilities.onRequest(request, onResult);
        }
    }
}