import * as React from 'react'
import { OptionModel } from '../../models/others/OtherTypes';
import { Utils } from './Utils';

interface IOptionPickerProps {
    options: OptionModel[];
    onChange: (status: any) => void;
}

interface IOptionPickerState { }

export default class OptionPicker extends React.Component<IOptionPickerProps, IOptionPickerState> {
    constructor(props: IOptionPickerProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { options } = this.props;

        return <div className="center" style={{
            display: "flex", width: "100%",
            backgroundColor: "aliceblue", height: 42, overflowX: "auto"
        }}>
            {options.map((option: OptionModel) => {
                return <div onClick={() => this.onClick(option)}
                    className={"product-picker-wrapper option-picker center pointer"}>
                    {option.text}
                    {!Utils.isNull(option.count) && option.count !== 0 &&
                        <span className="count">{option.count >= 10 ? "+9" : option.count.toString()}</span>}
                    {option.current && <div className="color-selected mt-5"></div>}
                </div>;
            })}
        </div>;
    }

    private onClick = (option: OptionModel) => {
        const { options } = this.props;

        for (let i = 0; i < options.length; i++) {
            const current = options[i].key === option.key;
            options[i].current = current;
        }

        this.setState({ options: options });

        this.props.onChange(option.key);
    }
}