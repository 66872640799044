import {
    ActionButton, ContextualMenu, getId, IDragOptions, SearchBox, Spinner, SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { Authentication } from '../common/Authentication';
import { DataUtilities, onResponseResultAction } from '../common/DataUtilities';
import { Utils } from '../common/Utils';
import {
    IconName, IGridColumn, IGridDataService, onColumnClick, onRowAction
} from '../grid/Grid';
import { GridUtilities } from '../grid/GridUtilities';
import { UserRolesInfo } from '../../models/accounts/UserRolesInfo';
import { IDataResponseResult, ResponseStatus } from '../../models/main/ResponseResult';
import {
    OrderFilterModel, OrderModel, OrderRejectReason, OrderStatus
} from '../../models/orders/OrderModel';
import { KeyValueModel, OptionModel } from '../../models/others/OtherTypes';
import { PagedRow, PageFilterModel, SortDirection } from '../../models/others/PageTypes';
import { ShortWebsiteModel } from '../../models/products/WebsiteModel';
import { OrderService } from '../../services/OrderService';
import OptionPicker from '../common/OptionPicker';
import { Page } from '../grid/Page';
import { Pager } from '../grid/Pager';
import { ProductUtilities } from '../products/ProductUtilities';
import NewOrderView from './NewOrderView';
import OrderDetailView from './OrderDetailView';
import OrderFilterView from './OrderFilterView';
import OrderGridDataView from './OrderGridDataView';

class OrderDataService implements IGridDataService {
    public sortColumns = ["id", "customer", "type", "date"];

    public get(model: PageFilterModel, onResult: onResponseResultAction) {
        let request = OrderService.search(model as OrderFilterModel);
        DataUtilities.onRequest(request, onResult);
    }

    public getColumns(onColumnClick: onColumnClick, onRowAction: onRowAction): IGridColumn[] {
        let list: IGridColumn[] = GridUtilities.createColumns(
            new OrderModel(),
            this.createOnRenders(),
            onColumnClick,
            ["id", "customer", "date", "type", "status", "reason"],
            [],
            { "id": 80, "customer": 120, "date": 200, "type": 100, "status": 100, "reason": 100 },
            {},
            this.sortColumns
        );

        list = list.concat(this.createCommandColumns(onRowAction));

        return list;
    }

    private createOnRenders(): any {
        return {
            status: (item: OrderModel) => <span className="active">{OrderStatus[item.status]}</span>,
            reason: (item: OrderModel) => <span>{item.reason ? OrderRejectReason[item.reason] : ""}</span>,
            date: (item: OrderModel) => <span>{Utils.formatLongDate(item.date)}</span>,
            product: (item: OrderModel) => <span>{item.product?.link}</span>,
        };
    }

    private createCommandColumns(onRowAction: onRowAction): IGridColumn[] {
        return GridUtilities.createRowActionIcons(this.getActions(), onRowAction);
    }

    public getActions() {
        var actions = [
            GridUtilities.getActionInfoForIcon(IconName.CustomList),
            GridUtilities.getActionInfoForIcon(IconName.Edit),
            GridUtilities.getActionInfoForIcon(IconName.Delete)
        ];

        return actions;
    }

    public getMobileUIRow(item: PagedRow): JSX.Element {
        const onRenders = this.createOnRenders();
        const order = item as OrderModel;

        return <>

        </>;
    }
}

interface IOrderViewProps { }

interface IOrderViewState {
    userRoles: UserRolesInfo;
    model: OrderFilterModel;
    orders: OrderModel[];
    totalCount: number;
    statusOptions: OptionModel[];
    websiteOptions: ShortWebsiteModel[];
    isLoading: boolean;
    listHash: number;
    pageHash: number;
    filterHash: number;
    selectedId: number;
    showAdd: boolean;
    showDetail: boolean;
    showFilter: boolean;
}

export default class OrderView extends React.Component<IOrderViewProps, IOrderViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    private _service: OrderDataService = new OrderDataService();
    //private _searchBox: JSX.Element;

    constructor(props: IOrderViewProps) {
        super(props);

        const model = new OrderFilterModel();
        model.pageSize = 25;
        model.sortDirection = SortDirection.ascending;
        model.status = OrderStatus.request;
        model.isAllWarehouses = true;

        this.state = {
            userRoles: Authentication.getUserRoles(),
            model: model,
            orders: [],
            totalCount: 0,
            statusOptions: this.initializeStatusOptions(),
            websiteOptions: [],
            isLoading: false,
            listHash: -1,
            pageHash: -1,
            filterHash: -1,
            selectedId: -1,
            showAdd: false,
            showDetail: false,
            showFilter: false
        };

        ProductUtilities.getOpenWebsites((list: ShortWebsiteModel[]) => this.setState({ websiteOptions: list }));
    }

    private initializeStatusOptions = () => {
        const option1 = new OptionModel();
        option1.key = OrderStatus.request;
        option1.text = "Request";
        option1.current = true;
        option1.count = 0;

        const option2 = new OptionModel();
        option2.key = OrderStatus.quote;
        option2.text = "Quote";
        option2.current = false;
        option2.count = 0;

        const option3 = new OptionModel();
        option3.key = OrderStatus.orderPending;
        option3.text = "Pending";
        option3.current = false;
        option3.count = 0;

        const option4 = new OptionModel();
        option4.key = OrderStatus.orderApproved;
        option4.text = "Approved";
        option4.current = false;
        option4.count = 0;

        const option5 = new OptionModel();
        option5.key = OrderStatus.closed;
        option5.text = "Closed";
        option5.current = false;
        option5.count = 0;

        return [option1, option2, option3, option4, option5];
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        const {
            model, orders, statusOptions, totalCount, isLoading, showAdd, showDetail, showFilter,
            selectedId, listHash, pageHash, websiteOptions
        } = this.state;

        const width6 = Utils.createPanelStyle(6, 12, 12);
        const width12 = Utils.createPanelStyle(12);

        return <div className="ms-Grid">
            <div className="ms-Grid-row pt-20">
                <div className={width12 + " ms-Grid"} >
                    <div className="ms-Grid-row" style={{ backgroundColor: "aliceblue" }}>
                        <div className={width6}>
                            {this.actionElements()}
                        </div>
                        <div className={width6}>
                            <OptionPicker options={statusOptions} onChange={this.statusChange} />
                        </div>
                    </div>

                    <div className="pv-20">
                        {isLoading && <Spinner size={SpinnerSize.large} />}

                        <OrderGridDataView
                            orders={orders}
                            key={listHash}
                            onDetail={(id: number) => this.setState({ showDetail: true, selectedId: id })}
                            onCompleted={this.onCompletedDetail} />
                    </div>

                    <div style={{ width: "100%", display: "inline-block" }}>
                        <Pager
                            totalCount={totalCount}
                            onChange={this.onPageChange}
                            pageSize={model.pageSize}
                            key={pageHash} />
                    </div>
                </div>
            </div>

            {showAdd && <NewOrderView onAction={this.onCompletedAddNew} />}
            {showDetail && <>
                <OrderDetailView
                    id={selectedId}
                    onCompleted={this.onCompletedDetail}
                    websiteOptions={websiteOptions} />
            </>}
            {showFilter && <>
                <OrderFilterView
                    filter={model}
                    onFilter={this.onFilterChange}
                    onClose={this.onCloseFilter} />
            </>}
        </div>;
    }

    private actionElements = () => {
        const { model } = this.state;

        return <div>
            <SearchBox
                autoFocus={false}
                value={model.search}
                placeholder="Search"
                onSearch={(value: string) => this.onValueChange("search", value)}
                onClear={() => this.onValueChange("search", "")}
                className="searchbox"
                styles={{ root: { width: "400px", display: "inline-flex" } }}
            />

            <ActionButton
                iconProps={{ iconName: "Refresh" }}
                onClick={() => this.getList(true)}>Refresh</ActionButton>

            <ActionButton
                iconProps={{ iconName: "Add" }}
                onClick={() => this.setState({ showAdd: true })}>Add New</ActionButton>

            <ActionButton
                iconProps={{ iconName: "Filter" }}
                onClick={() => this.setState({ showFilter: true })}>Filter</ActionButton>
        </div>;
    }

    private onValueChange = (name: string, value: any) => {
        const model = this.state.model as any;
        model[name] = value;
        model.pageNumber = 1;

        this.setState({ model: model }, this.getList);
    }

    private onPageChange = (page: Page) => {
        if (page) {
            let { model } = this.state;

            model.pageNumber = page.pageNumber;
            if (model.pageSize !== page.pageSize) model.pageNumber = 1;
            model.pageSize = page.pageSize;

            this.setState({ model: model }, () => this.getList(false, true));
        }
    }

    private statusChange = (status: OrderStatus) => {
        const { model } = this.state;
        model.status = status;
        model.pageNumber = 1;
        this.setState({ model: model }, () => this.getList(false));
    }

    private onFilterChange = (newFilter: OrderFilterModel) => {
        this.setState({ model: newFilter, showFilter: false }, this.getList);
    }

    private onCloseFilter = () => {
        this.setState({ showFilter: false });
    }

    private getCountByStatus = () => {
        const onResult = (result: IDataResponseResult) => {
            if (result.status !== ResponseStatus.fail) {
                const counts = result.data as KeyValueModel[];

                if (counts) {
                    const { statusOptions } = this.state;
                    for (let i = 0; i < statusOptions.length; i++) {
                        const option = statusOptions[i];
                        const status = option.key;
                        const count = counts.find((item: KeyValueModel) => item.key === status);

                        option.count = count ? count.value : 0;
                    }

                    this.setState({ statusOptions: statusOptions });
                }
            }
        }

        const { model } = this.state;
        const request = OrderService.getCountByStatus(model);
        DataUtilities.onRequest(request, onResult);
    }

    private getList = (getCount = true, pageChange = false) => {
        const { model } = this.state;

        if (!model.status) model.status = OrderStatus.all;
        if (!model.reason) model.reason = undefined;

        this.setState({ orders: [], isLoading: true, listHash: Utils.getRandomKey() });

        const request = OrderService.search(model);
        DataUtilities.onRequest(request, (result: IDataResponseResult) => this.getListResult(result, pageChange));

        if (getCount) this.getCountByStatus();
    }

    private getListResult = (result: IDataResponseResult, pageChange = false) => {
        if (result.status === ResponseStatus.success) {
            this.setState({
                orders: result.data.items,
                listHash: Utils.getRandomKey(),
                totalCount: result.data.totalCount
            });

            if (!pageChange) { this.setState({ pageHash: Utils.getRandomKey() + 100 }); }

            this.setState({ isLoading: false });
        }
    }

    private onCompletedAddNew = (refresh: boolean) => {
        this.setState({ showAdd: false });

        if (refresh) this.getList();
    }

    private onCompletedDetail = () => {
        if (window.confirm("Would you like to refresh the data?")) {
            this.getList();
        }

        this.setState({ showDetail: false });
    }
}