import React from 'react';
import { IIconProps, getTheme, mergeStyleSets, FontWeights, getId, IModalStyles, Icon } from '@fluentui/react';
import { emptyFunc } from '../../models/others/OtherTypes';

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const contentStyles = mergeStyleSets({
    header: [
        // tslint:disable-next-line:deprecation
        theme.fonts.medium,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold
        },
    ]
});

export class StyleUtils {
    public static modelHeader(header: string, onClose: emptyFunc): JSX.Element {
        const titleId: string = getId('title');
        return <div className={contentStyles.header}>
            <div className="fl-grid">
                <div className="fl-span10"><b id={titleId}>{header}</b></div>
                <div className="fl-span2">
                    {onClose && <Icon iconName="ChromeClose" onClick={onClose} />}
                </div>
            </div>
        </div>;
    }

    public static modelElementHeader(header: JSX.Element, onClose: emptyFunc): JSX.Element {
        const titleId: string = getId('title');
        return <div className={contentStyles.header}>
            <b id={titleId}>{header}</b>
            <Icon iconName="ChromeClose" onClick={onClose} />
        </div>;
    }

    public static getModalWidthStyles(width: number) {
        const styles: IModalStyles = {
            main: { width: width, maxWidth: "100%" }, root: { left: 0, overflowY: "hidden" }
        } as IModalStyles;
        return styles;
    }

    public static getProductItemStyles(width: any, height: any, minWidth: any = null) {
        if (!minWidth) minWidth = width;

        const styles: React.CSSProperties = {
            width: width, minWidth: minWidth, maxWidth: "100%",
            height: height, paddingTop: 15
        };
        return styles;
    }
}