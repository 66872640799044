import { Utils } from "../common/Utils";
import { FileCategory, FileType, PictureModel } from "../../models/products/PictureModel";
import { ProductModel } from "../../models/products/ProductModel";
import { baseUrl } from "../../constants/url.constant";
import { emptyFunc } from "../grid/Grid";
import { DocumentCard, DocumentCardImage, ImageFit } from "@fluentui/react";
import { DataUtilities } from "../common/DataUtilities";
import { IDataResponseResult } from "../../models/main/ResponseResult";
import { WebsiteService } from "../../services/WebsiteService";
import { ShortWebsiteModel } from "../../models/products/WebsiteModel";
import { MyTooltip } from "../common/MyTooltip";

export type productFunc = (product: ProductModel) => void;
export type websitesCallback = (list: ShortWebsiteModel[]) => void;

export class ProductUtilities {
    public static getProductImageUrl(picture?: PictureModel) {
        return picture && !Utils.isNullOrEmpty(picture.imageUrl) ? baseUrl + picture.imageUrl : "";
    }

    public static printShortProductInfo(product: ProductModel, onQuickDetail: productFunc): JSX.Element {
        return <div style={{ fontSize: "small" }}>
            {!Utils.isNullOrEmpty(product.name) && <div className="pt-5">
                <div className={"product-name"}>{product.name}</div>
            </div>}
            {!Utils.isNullOrEmpty(product.description) && <div className="pt-5">
                <div className={"product-name"}>{product.description}</div>
            </div>}
        </div>;
    }

    public static createMediaElement(fileType: FileType, url: string, size: any): JSX.Element {
        let element = <></>;

        if (fileType === FileType.video) {
            element = <video style={{ maxWidth: "100%", maxHeight: "100%" }}
                controls><source src={url} type="video/mp4" /></video>;
        } else if (fileType === FileType.others) {
            element = <a href={url} target="_blank">pdf</a>;
        } else {
            if (Utils.isNull(size)) size = Utils.pictureFileSizes["Mobile"];
            element = <DocumentCardImage imageFit={ImageFit.centerCover}
                className="product-picture image-radius" imageSrc={url} height={size.height} />;
        }

        return element;
    }

    public static createImageElement2(type: FileType, url: string, category: FileCategory,
        size: any, onClick: emptyFunc): JSX.Element {
        if (!size) {
            size = !Utils.isNull(category) ? Utils.pictureFileSizes[FileCategory[category].toString()] : null;
        }

        if (Utils.isNullOrEmpty(url)) return <></>;

        return <DocumentCard onClick={onClick} className="picture-wrapper pb-10"
            styles={{ root: { maxWidth: "100%" } }}
            style={{ width: "100%", minWidth: "100%" }}>
            {this.createMediaElement(type, url, size)}
        </DocumentCard>;
    }

    public static getOpenWebsites(callback: websitesCallback) {
        const onResult = (result: IDataResponseResult) => {
            const list = result.data as ShortWebsiteModel[];
            callback(list);
        };

        var request = WebsiteService.getOpenList();
        DataUtilities.onRequest(request, onResult);
    }

    public static getMiniPicture(type: FileType, url: string, text: string, onClick: emptyFunc, width = 50): JSX.Element {
        if (type === FileType.video) {
            url = "/dist/vendor/video.png";
        } else if (type === FileType.others) {
            url = "/dist/vendor/file.png";
        }

        if (Utils.isValidUrl(baseUrl + url)) {
            url = baseUrl + url;
        }

        const element = <DocumentCardImage width={width} height={width} imageFit={ImageFit.centerCover}
            className="mini-product-picture" imageSrc={url} styles={{ centeredIcon: { zIndex: 100 } }} />;

        return <DocumentCard onClick={onClick} className="mini-picture-wrapper"
            style={{ width: width + 8, minWidth: width + 8 }}>
            {!Utils.isNullOrEmpty(text) && <MyTooltip element={element} text={text} />}
            {Utils.isNullOrEmpty(text) && element}
        </DocumentCard>;
    }

    public static getLargePicture(type: FileType, url: string, onClick: emptyFunc): JSX.Element {
        if (Utils.isNullOrEmpty(url)) return <></>;

        return <DocumentCard onClick={onClick} className="picture-wrapper"
            styles={{ root: { maxWidth: "100%", height: 480, textAlign: "center" } }}>
            {this.getMedia(type, url)}
        </DocumentCard>;
    }

    public static getMedia(fileType: FileType, url: string): JSX.Element {
        if (Utils.isValidUrl(baseUrl + url)) {
            url = baseUrl + url;
        }

        let element = <></>;

        if (fileType === FileType.video) {
            element = <video style={{ maxWidth: "100%", maxHeight: "100%" }}
                controls><source src={url} type="video/mp4" /></video>;
        } else if (fileType === FileType.others) {
            element = <a href={url} target="_blank">pdf</a>;
        } else {
            element = <img src={url} className="image-radius"
                style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: 475 }} />
        }

        return element;
    }
}