import * as React from 'react';
import { CheckboxFilter, Filter } from './GridFilters';
import { Checkbox } from '@fluentui/react';

interface CheckboxFilterComponentProps {
    filter: CheckboxFilter;
    value: boolean;
    onValueChange: (name: string, value: boolean) => void;
}

interface CheckboxFilterComponentState {
    value: boolean;
}

export class CheckboxFilterComponent extends React.Component<CheckboxFilterComponentProps, CheckboxFilterComponentState> {
    constructor(props: CheckboxFilterComponentProps) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    render() {
        const { filter } = this.props;
        const { value } = this.state;

        return <div className="pt-10">
            <Checkbox
                checked={value}
                label={filter.label}
                onChange={this.onValueChange} />
        </div>
    }

    private onValueChange = (event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, newValue: boolean | undefined) => {
        const { filter, value, onValueChange } = this.props;
        this.setState({ value: newValue ?? false });
        onValueChange(filter.name, newValue ?? false);
    }
}