import * as React from 'react';
import {
    DirectionalHint, TextField, Checkbox, Dropdown, IDropdownOption, IComboBoxOption,
    PrimaryButton, DefaultButton, Callout
} from '@fluentui/react';
import { BasicDatePicker } from '../common/BasicDatePicker';
import { TextboxFilter, CheckboxFilter, ComboboxFilter, DropdownFilter, Filter } from './GridFilters';
import { PageFilterModel } from '../../models/others/PageTypes';
import { Utils } from '../common/Utils';
import { CheckboxFilterComponent } from './CheckboxFilterComponent';

interface GridFilterProps {
    id: string;
    model: PageFilterModel;
    filters: Filter[];
    onSubmit: (model: PageFilterModel) => void;
    onCancel: () => void;
}

interface GridFilterState {
    model: PageFilterModel;
}

export class GridFilter extends React.Component<GridFilterProps, GridFilterState> {
    private _leftFilters: Filter[];
    private _rightFilters: Filter[];
    private _width6 = Utils.createPanelStyle(6);
    private _width12 = Utils.createPanelStyle(12);

    constructor(props: GridFilterProps) {
        super(props);

        this.state = {
            model: this.initializeModel(props.model)
        };

        this._leftFilters = props.filters.filter((filter: Filter, index: number) => index % 2 === 0);
        this._rightFilters = props.filters.filter((filter: Filter, index: number) => index % 2 !== 0);
    }

    private initializeModel(propsModel: any) {
        let model: any = {};

        for (let key in propsModel) model[key] = propsModel[key];

        return model;
    }

    render() {
        const { id, onCancel, onSubmit } = this.props;

        return <>
            <Callout
                gapSpace={5}
                target={"#" + id}
                isBeakVisible={false}
                calloutWidth={450}
                onDismiss={onCancel}
                directionalHint={DirectionalHint.bottomCenter}>
                <div className="p-15">
                    <div className="fl-grid">
                        <div className="fl-span6">
                            {this.startDateElement()}
                            {this._leftFilters.map((filter: Filter, index: number, arr: Filter[]) =>
                                this.generateElement(filter))}
                        </div>
                        <div className="fl-span6">
                            {this.endDateElement()}
                            {this._rightFilters.map((filter: Filter, index: number, arr: Filter[]) =>
                                this.generateElement(filter))}
                        </div>
                    </div>

                    <div className="fl-grid">
                        <div className={"fl-span12 pt-15 right"}>
                            <span className="pr-10">
                                <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
                            </span>
                            <PrimaryButton onClick={() => onSubmit(this.state.model)}>Submit</PrimaryButton>
                        </div>
                    </div>
                </div>
            </Callout>
        </>;
    }

    private startDateElement = () => {
        const { model } = this.state;

        return <>
            <BasicDatePicker args={{
                label: "From",
                onSelectDate: (date: Date | null | undefined) => { this.onValueChange("startDate", date) },
                value: model.startDate,
                maxDate: model.endDate
            }} />
        </>;
    }

    private endDateElement = () => {
        const { model } = this.state;

        return <>
            <BasicDatePicker args={{
                label: "To",
                onSelectDate: (date: Date | null | undefined) => { this.onValueChange("endDate", date) },
                value: model.endDate,
                minDate: model.startDate
            }} />
        </>;
    }

    private generateElement = (filter: Filter): JSX.Element => {
        const model = this.state.model as any;

        if (filter instanceof TextboxFilter)
            return this.createTextboxFilter(filter, model[filter.name]);
        else if (filter instanceof CheckboxFilter)
            return <>
                <CheckboxFilterComponent
                    filter={filter}
                    value={model[filter.name]}
                    onValueChange={this.onValueChange} />
            </>;
        else if (filter instanceof DropdownFilter)
            return this.createDropdownFilter(filter as DropdownFilter, model[filter.name]);
        else if (filter instanceof ComboboxFilter)
            return this.createComboboxFilter(filter as ComboboxFilter);

        return <></>;
    }

    private createTextboxFilter = (filter: TextboxFilter, currentValue: string): JSX.Element => {
        return <>
            <TextField
                value={currentValue}
                label={filter.label}
                onChange={(event, newValue) => this.onValueChange(filter.name, newValue)} />
        </>;
    }

    private createCheckboxFilter = (filter: CheckboxFilter, currentValue: boolean): JSX.Element => {
        return <div className="pt-10">
            <Checkbox
                checked={currentValue}
                label={filter.label}
                onChange={(event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, newValue?: boolean) =>
                    this.onValueChange(filter.name, newValue)} />
        </div>
    }

    private createComboboxFilter = (filter: ComboboxFilter): JSX.Element => {
        const onChange = (option: IComboBoxOption) => {
            this.onValueChange(filter.name, option.key);
            filter.text = option.text;
        }

        if (filter.component) return filter.component(onChange);

        return <></>;
    }

    private createDropdownFilter = (filter: DropdownFilter, value: any): JSX.Element => {
        if (filter.isMultiSelect) {
            return this.createMultiSelectDropdownFilter(filter, value);
        }

        return this.createSingleSelectDropdownFilter(filter, value);
        //return <DropdownFilterContainer filter={filter} onValueChange={this.onValueChange} />
    }

    private createSingleSelectDropdownFilter = (filter: DropdownFilter, currentValue: any): JSX.Element => {
        return <Dropdown
            label={filter.label}
            selectedKey={currentValue}
            options={filter.options}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) =>
                this.onValueChange(filter.name, option ? option.key : "")}
        />
    }

    private createMultiSelectDropdownFilter = (filter: DropdownFilter, currentValues: any[]): JSX.Element => {
        return <Dropdown
            label={filter.label}
            selectedKeys={currentValues}
            multiSelect
            options={filter.options}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                const model = this.state.model as any;
                let oldValues = model[filter.name];
                if (!oldValues) oldValues = [];

                if (option) {
                    if (option.selected) {
                        oldValues.push(option.key);
                    } else {
                        const oIndex = oldValues.indexOf(option.key);
                        if (oIndex > -1) oldValues.splice(oIndex, 1);
                    }
                }

                this.onValueChange(filter.name, currentValues);
            }} />
    }

    private onValueChange = (name: string, value: any) => {
        const model = this.state.model as any;
        model[name] = value;
        this.setState({ model: model });
    }
}