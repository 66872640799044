export enum ContactType {
    phone = 0, email = 1, url = 2
}

export class ContactInfo {
    public id: number = 0;
    public type: ContactType = ContactType.phone;
    public name: string = "";
    public account1: string = "";
    public account2: string = "";
    public pictureUrl?: string;
    public description?: string;
    public active: boolean = false;
    public deleted: boolean = false;
}