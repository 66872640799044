import { ContextualMenu, getId, Icon, IDragOptions, Modal, PrimaryButton, ProgressIndicator } from '@fluentui/react';
import * as React from 'react';
import { FileCategory, FileType, UploadPictureModel } from '../../models/products/PictureModel';
import PictureUpload from './PictureUpload';
import { StyleUtils } from '../common/StyleUtils';
import { Utils } from '../common/Utils';

interface IAddPictureViewProps {
    ownerId: number;
    onSubmit: (picture: UploadPictureModel) => void;
    onClose: () => void;
}

interface IAddPictureViewState {
    model: UploadPictureModel;
    isSubmitting: boolean;
}

export default class AddPictureView extends React.Component<IAddPictureViewProps, IAddPictureViewState> {
    private _titleId: string = getId('title');
    private _subtitleId: string = getId('subText');
    private _dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    };

    constructor(props: IAddPictureViewProps) {
        super(props);

        const model = new UploadPictureModel();
        model.category = FileCategory.feature;
        model.type = FileType.image;

        this.state = {
            model: model,
            isSubmitting: false
        }
    }

    render() {
        const { onClose, onSubmit } = this.props;
        const { model, isSubmitting } = this.state;
        const width2 = Utils.createPanelStyle(2);
        const width10 = Utils.createPanelStyle(10);

        return <>
            <Modal
                titleAriaId={this._titleId}
                subtitleAriaId={this._subtitleId}
                isOpen={true}
                onDismiss={onClose}
                isBlocking={true}
                isModeless={false}
                styles={StyleUtils.getModalWidthStyles(500)}
            >
                <div className="popup popup-header">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className={width10}><b>Add Picture</b></div>
                            <div className={width2 + " right"}>
                                <Icon iconName="ChromeClose" className="pointer" onClick={onClose} />
                            </div>
                        </div>
                    </div>
                </div>
                <div id={this._subtitleId} className="popup-body">
                    <PictureUpload
                        isEdit={false}
                        onAction={this.onUploadFile}
                        fileType={model.type}
                        fileCategory={model.category}
                        imageUrl={undefined} />

                    {!isSubmitting && <div className="ms-Grid-row center pt-10">
                        <PrimaryButton onClick={() => onSubmit(model)}>Submit</PrimaryButton>
                    </div>}

                    {isSubmitting && <div className="pt-20">
                        <ProgressIndicator label="Submitting" description="" />
                    </div>}
                </div>
            </Modal>
        </>;
    }

    private onUploadFile = (file?: File) => {
        const { model } = this.state;
        model.file = file;
        this.setState({ model: model });
    }
}