import React from "react";
import LoginView from "../../components/accounts/LoginView";
import "./login.scss";

const Login: React.FC = () => {
    return (
        <div className="ph-10">
            <LoginView />
        </div>
    );
};

export default Login;