import * as React from 'react';
import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType, Spinner, SpinnerSize } from '@fluentui/react';
import { Utils } from '../common/Utils';
import { CustomerUtilities } from './CustomerUtilities';
import { CustomerDropdownItem } from '../../models/others/OtherTypes';

interface ICustomerDropdownProps {
    label?: string;
    defaultValue?: CustomerDropdownItem;
    onSelectChange: (option: CustomerDropdownItem) => void;
    disabled?: boolean;
    userOnly?: boolean;
}

interface ICustomerDropdownState {
    model: CustomerDropdownItem;
    searchOptions: IComboBoxOption[];
    wholeSaleOptions: IComboBoxOption[];
    employeeOptions: IComboBoxOption[];
    recentOptions: IComboBoxOption[];
    isLoading: boolean;
}

export class CustomerDropdown extends React.Component<ICustomerDropdownProps, ICustomerDropdownState> {
    private _customerComboBox = React.createRef<IComboBox>();
    private _recentCustomerMap = new Map();

    constructor(props: ICustomerDropdownProps) {
        super(props);

        let recentOptions = [];

        if (props.defaultValue && this.props.defaultValue && this.props.defaultValue.id) {
            recentOptions.push({ key: props.defaultValue.id, text: props.defaultValue.name });
        }

        this.state = {
            model: props.defaultValue ? props.defaultValue : new CustomerDropdownItem(),
            searchOptions: [],
            wholeSaleOptions: [],
            employeeOptions: [],
            recentOptions: recentOptions,
            isLoading: false
        };
    }

    componentDidMount() {
        const { userOnly } = this.props;

        CustomerUtilities.getEmployeeOptions((list: IComboBoxOption[]) =>
            this.setState({ employeeOptions: list }));
    }

    public render() {
        const { model, isLoading } = this.state;
        const label = !Utils.isNullOrEmpty(this.props.label) ? this.props.label : "Customer";

        return <>
            <ComboBox
                label={label}
                allowFreeform
                selectedKey={model.id}
                componentRef={this._customerComboBox}
                options={this.getCustomerOptions()}
                onChange={this.onValueChange}
                disabled={isLoading || this.props.disabled} />

            {isLoading && <div className="pt-10"><Spinner size={SpinnerSize.small} /></div>}
        </>;
    }

    private getCustomerOptions = () => {
        const { recentOptions, searchOptions, wholeSaleOptions, employeeOptions } = this.state;

        let recentOpts: IComboBoxOption[] = [];
        if (recentOptions && recentOptions.length > 0) {
            recentOpts.push({ key: "Recent Customer Header", text: "Recent", itemType: SelectableOptionMenuItemType.Header });
            recentOpts = recentOpts.concat(recentOptions);
            recentOpts.push({ key: "Recent Customer Divider", text: "-", itemType: SelectableOptionMenuItemType.Divider });
        }

        let options = searchOptions
            .concat(recentOpts)
            .concat(employeeOptions)
            .concat(wholeSaleOptions);

        return options;
    }

    private onValueChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        const { onSelectChange, userOnly } = this.props;

        if (option) {
            this.setState({ model: option.data as CustomerDropdownItem });
            onSelectChange(option.data);

            if (!this._recentCustomerMap.has(option.key)) {
                this._recentCustomerMap.set(option.key, true);
                const { recentOptions } = this.state;
                recentOptions.push(option);
                this.setState({ recentOptions: recentOptions });
            }
        } else {
            this.setState({ isLoading: true });

            if (value) {
                CustomerUtilities.getCustomerOptions(value, userOnly ?? false, (list: IComboBoxOption[]) => {
                    this.setState({ searchOptions: list, isLoading: false }, () => {
                        if (this._customerComboBox.current)
                            this._customerComboBox.current.focus(true);
                    });
                });
            } else {
                onSelectChange(new CustomerDropdownItem());
            }
        }
    }
}