import React from "react";
import OrderView from "../../components/orders/OrderView";

const Order: React.FC = () => {
    return (
        <div>
            <OrderView />
        </div>
    );
};

export default Order;