import { Utils } from "../../components/common/Utils";
import { DateAvailability } from "../others/OtherTypes";
import { PageFilterModel } from "../others/PageTypes";
import { PhotoCollagePostModel } from "../products/PictureModel";

export enum PhotoCollageDisplayCount {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5
}

export enum PhotoCollageLayout {
    horizontalHalf = 1,
    horizontalTwoThird = 2,
    verticalHalf = 3,
    verticalTwoThird = 4,
    allSquare = 5
}

export enum PhotoSize {
    oneThird = 0,
    half = 1,
    twoThird = 2,
    full = 3,
    extraFull = 4
}

export class PhotoDimension {
    public width: number
    public height: number

    constructor(width: number, height?: number) {
        this.width = width

        if (height) {
            this.height = height
        } else {
            this.height = width
        }
    }

    public getExtraWidth(): number {
        return this.width * 1.15
    }

    public getSpacing(): number {
        return this.width * 0.01
    }

    public getHalf(): number {
        return (this.width - this.getSpacing()) / 2.0;
    }

    public getOneThird(spaceCount: number = 2): number {
        return (this.width - this.getSpacing() * spaceCount) / 3.0;
    }

    public getTwoThird(): number {
        return (this.width - this.getSpacing()) * 2.0 / 3.0;
    }
}

export class PhotoCollageStyle {
    public space: number
    public tall: boolean
    public outerFloat: any
    public innerFloat: any
    public paddingRight: number
    public paddingBottom: number
    public innerDisplay: string

    constructor(space: number, tall: boolean) {
        this.space = space;
        this.tall = tall;

        if (tall) {
            this.outerFloat = "left";
            this.innerFloat = "right";
            this.paddingRight = 0;
            this.paddingBottom = space;
            this.innerDisplay = "flex";
        } else {
            this.outerFloat = "unset";
            this.innerFloat = "left";
            this.paddingRight = space;
            this.paddingBottom = 0;
            this.innerDisplay = "inline-block";
        }
    }
}

export class PhotoCollageLayoutManager {
    public layout: PhotoCollageLayout

    constructor(layout: PhotoCollageLayout) {
        this.layout = layout;
    }

    public getName(): string {
        return Utils.splitCapitalCase(PhotoCollageLayout[this.layout]);
    }

    public isTall(): boolean {
        return this.layout === PhotoCollageLayout.horizontalHalf ||
            this.layout === PhotoCollageLayout.horizontalTwoThird;
    }

    public isHalf(): boolean {
        return this.layout === PhotoCollageLayout.horizontalHalf ||
            this.layout === PhotoCollageLayout.verticalHalf ||
            this.layout === PhotoCollageLayout.allSquare;
    }
}

export class PhotoCollageTemplateModel {
    public id: number = 0;
    public displayCount: PhotoCollageDisplayCount = PhotoCollageDisplayCount.one;
    public layout: PhotoCollageLayout = PhotoCollageLayout.allSquare;
}

export class PhotoCollageInputModel {
    public id: number = 0;
    public brand: string = "";
    public title: string = "";
    public description: string = "";
    public url?: string = undefined;
    public start: Date = new Date(0);
    public end: Date = new Date(0);
}

export class PhotoCollageModel extends PhotoCollageInputModel {
    public displayCount: PhotoCollageDisplayCount = PhotoCollageDisplayCount.one;
    public layout: PhotoCollageLayout = PhotoCollageLayout.allSquare;
    public active: boolean = false;
    public pictures: PhotoCollagePostModel[] = [];
    public availability: DateAvailability = DateAvailability.expired;
}

export class PhotoCollageFilterModel extends PageFilterModel {
    isOpen: boolean = false;
}

//export class PromotionPostModel implements PagedRow {
//	public id: number = 0;
//	public brand: string = "";
//	public title: string = "";
//	public availableTime: string = "";
//	public pictures: ShortPictureInfo[] = [];
//    public displayCount: PhotoCollageDisplayCount = PhotoCollageDisplayCount.one;
//    public layout: PhotoCollageLayout = PhotoCollageLayout.allSquare;
//	public isLike: boolean = false;
//	public isFavorite: boolean = false;
//	public likes: number = 0;
//	public shares: number = 0;
//	public views: number = 0;
//}
